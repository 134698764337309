<div class="container pl-container-page">
  <form autocomplete="off" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-md-12">
        
        <!-- Employment status -->
        <div class="form-group">
          <div class="font-size-14 font-size-md-16 font-weight-bold mb-4">What is your current employment status?</div>
          <div class="d-flex flex-row flex-wrap gap-20 justify-content-between">
            <div class="btn-wrapper" *ngFor="let status of ['Full-time employed', 'Part-time employed', 'Self-employed', 'Contractor', 'Unemployed', 'Pensioner']">
              <button 
                class="btn btn-with-icon d-flex align-items-center gap-8"
                [ngClass]="{'active': selectedEmploymentStatus === status}"
                (click)="onEmploymentStatusChange(status)">
                <span>{{ status }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Employer Name -->
      <div class="col-12 col-md-12">
        <div class="font-size-14 font-size-md-16 font-weight-bold mt-5 mb-4">Enter your current employment details</div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="employer_name">Company name</label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput id="employer_name" formControlName="employer_name">
          </mat-form-field>
        </div>
      </div>

      <!-- Employer Start Year Month -->
      <div class="col-12 col-md-6">
        <div class="form-group">
          
          <div class="row">
            <!-- Starting Month -->
            <div class="col-6">
              <label class="font-size-16" for="employer_start_month">Starting month</label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select id="employer_start_month" formControlName="employer_start_month">
                  <mat-option *ngFor="let month of months" [value]="month.value">{{ month.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('employer_start_month').hasError('required')">
                  Starting month is required.
                </mat-error>
                <mat-error *ngIf="form.get('employer_start_month').hasError('pattern')">
                  Please select a valid month.
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Starting Year -->
            <div class="col-6">
              <label class="font-size-16" for="employer_start_month">Starting year</label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select id="employer_start_year" formControlName="employer_start_year">
                  <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Ethnicity -->
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="ethnicity">Ethnicity</label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select id="ethnicity" formControlName="ethnicity" placeholder="Select">
              <mat-option value="African">African</mat-option>
              <mat-option value="Indian">Indian</mat-option>
              <mat-option value="White">White</mat-option>
              <mat-option value="Coloured">Coloured</mat-option>
              <mat-option value="Other">Other</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('ethnicity').hasError('required')">Ethnicity is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Salary Date Start -->
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="salary_date">Salary date</label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select id="salary_date" formControlName="salary_date">
              <mat-option *ngFor="let date of dates" [value]="date">{{ date }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Address Details -->
      <div class="col-12 col-md-12">
        <div class="font-size-14 font-size-md-16 font-weight-bold mt-5 mb-4">Enter your residential address details</div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="address_street">Street address</label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput id="address_street" formControlName="address_street">
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="address_suburb">Suburb</label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput id="address_suburb" formControlName="address_suburb">
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="address_city">City</label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput id="address_city" formControlName="address_city">
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="address_province">Province</label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select id="address_province" formControlName="address_province">
              <mat-option *ngFor="let province of provinces" [value]="province">{{ province }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="address_postal_code">Postal code</label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput id="address_postal_code" formControlName="address_postal_code">
          </mat-form-field>
        </div>
      </div>

      <!-- Bank Details -->
      <div class="col-12 col-md-12">
        <div class="font-size-14 font-size-md-16 font-weight-bold mt-5 mb-4">Enter your bank details</div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="bank_name">Bank name</label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select id="bank_name" formControlName="bank_name" placeholder="Select">
              <mat-option *ngFor="let bank of bankNames" [value]="bank">{{ bank }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="account_number">Account number</label>
          <mat-form-field appearance="outline" class="w-100">
            <input matInput id="account_number" formControlName="account_number">
          </mat-form-field>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label class="font-size-16" for="account_type">Account type</label>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select id="account_type" formControlName="account_type" placeholder="Select">
              <mat-option value="Savings">Savings</mat-option>
              <mat-option value="Current">Current</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
