<div class="popover-container">
    <ng-content></ng-content>
</div>



<!-- Exmples -->
<!-- <button mat-raised-button [popoverTrigger]="personalData">
    enter personal data
  </button>
  
  <button mat-raised-button [popoverTrigger]="dataList">
    view personal data
  </button>
  
  <p [popoverTrigger]="emptyPopover">
    Popover on text
  </p>
  
  <ng-template #emptyPopover>
    <popover-container>
      <p>example of a popover triggered by text</p>
      <button mat-raised-button color="primary" (click)="closePopover()">
        Close
      </button>
    </popover-container>
  </ng-template>
  
  <ng-template #dataList>
    <popover-container>
      <mat-list role="list">
        <mat-list-item role="listitem">Name: {{name}}</mat-list-item>
        <mat-list-item role="listitem">Phone: {{phone}}</mat-list-item>
        <mat-list-item role="listitem">Address: {{address}}</mat-list-item>
      </mat-list>
      <button mat-raised-button color="primary" (click)="closePopover()">
        Close
      </button>
    </popover-container>
  </ng-template>
  
  <ng-template #personalData>
    <popover-container>
      <mat-form-field class="example-form-field">
        <mat-label>Enter your name</mat-label>
        <input matInput type="text" [(ngModel)]="name">
      </mat-form-field>
      <br>
      <mat-form-field class="example-form-field">
        <mat-label>Enter your phone</mat-label>
        <input matInput type="text" [(ngModel)]="phone">
      </mat-form-field>
      <br>
      <mat-form-field class="example-form-field">
        <mat-label>Enter your address</mat-label>
        <input matInput type="text" [(ngModel)]="address">
      </mat-form-field>
      <br>
      <button mat-raised-button color="primary" (click)="closePopover()">
        Save
      </button>
    </popover-container>
  </ng-template>
  
  <button mat-raised-button [popoverTrigger]="popover" [closeOnClickOutside]="true">
    click to open
  </button>
  
  <ng-template #popover>
    <popover-container>
      <p>popover test with close on outside click</p>
    </popover-container>
  </ng-template> -->
  