<div *ngIf="isLoading" >

  <!-- OTP Verification Component -->
  <app-otp-verification [identifier]="identifier" (otpSuccess)="handleOtpSuccess($event)"></app-otp-verification> 

  <app-banner [currentStep]="0" [data]="bannerData"></app-banner>

  <div class="d-flex justify-content-center align-items-center" style="height: 40rem;">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>



<div  *ngIf="!isLoading" class="">
  <app-banner [currentStep]="stepper.selectedIndex" [data]="bannerData"></app-banner>
  
  <div class="container my-4">
  <div class="list-tab-steps container">
    <div class="tab-step flex-row">
      <ng-container [formGroup]="applicationForm">
        <mat-stepper #stepper [linear]="true">
          <mat-step [stepControl]="applicationForm.get('stepAmount')">
            <ng-template matStepLabel>
              <div (click)="updateApplicationStep(SubStatusIdentifier.in_progress_application_started)" [ngClass]="{'tab-item': true, 'tab-active': stepper.selectedIndex === 0}" class="justify-content-center align-items-center">
                <div class="" type="popIn" direction="normal">Amount</div>
                <div class="tab-border"></div>
              </div>
            </ng-template>
            
            <app-step-amount formGroupName="stepAmount" [application]="application"></app-step-amount>
          </mat-step>

          <mat-step [stepControl]="applicationForm.get('stepApplicant')">
            <ng-template matStepLabel>
              <div (click)="updateApplicationStep(SubStatusIdentifier.in_progress_applicant_details_confirmed)" [ngClass]="{'tab-item': true, 'tab-active': stepper.selectedIndex === 1}" class="justify-content-center align-items-center">
                <div class="" type="popIn" direction="normal">Applicant details</div>
                <div class="tab-border"></div>
              </div>
            </ng-template>
            <!-- Back Button -->
            <div class="">
              <div *ngIf="stepper.selectedIndex > 0"><div class="btn-icon-back" aria-hidden="true" (click)="goBack()">
                  <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3562 11H9.49882L16.3186 5.4L14.6137 4L4.87109 12L14.6137 20L16.3186 18.6L9.49882 13H24.3562V11Z" fill="#231F20"></path></svg></div>
              </div>
            </div>
            <app-step-applicant formGroupName="stepApplicant" [application]="application"></app-step-applicant>
          </mat-step>

          <mat-step [stepControl]="applicationForm.get('stepAffordability')">
            <ng-template matStepLabel>
              <div (click)="updateApplicationStep(SubStatusIdentifier.in_progress_affordability_declaration_completed)" [ngClass]="{'tab-item': true, 'tab-active': stepper.selectedIndex === 2}" class="justify-content-center align-items-center">
                <div class="" type="popIn" direction="normal">Affordability</div>
                <div class="tab-border"></div>
              </div>
            </ng-template>
            <!-- Back Button -->
            <div class="">
              <div *ngIf="stepper.selectedIndex > 0"><div class="btn-icon-back" aria-hidden="true" (click)="goBack()">
                  <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3562 11H9.49882L16.3186 5.4L14.6137 4L4.87109 12L14.6137 20L16.3186 18.6L9.49882 13H24.3562V11Z" fill="#231F20"></path></svg></div>
              </div>
            </div>
            <app-step-affordability formGroupName="stepAffordability" [application]="application"></app-step-affordability>
          </mat-step>

          <mat-step [stepControl]="applicationForm.get('stepSummary')">
            <ng-template matStepLabel>
              <div (click)="updateApplicationStep(SubStatusIdentifier.in_progress_summary_accepted)" [ngClass]="{'tab-item': true, 'tab-active': stepper.selectedIndex === 3}" class="justify-content-center align-items-center">
                <div class="" type="popIn" direction="normal" style="color: black;">Summary</div>
                <div class="tab-border"></div>
              </div>
            </ng-template>
            <!-- Back Button -->
            <div class="">
              <div *ngIf="stepper.selectedIndex > 0"><div class="btn-icon-back" aria-hidden="true" (click)="goBack()">
                  <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.3562 11H9.49882L16.3186 5.4L14.6137 4L4.87109 12L14.6137 20L16.3186 18.6L9.49882 13H24.3562V11Z" fill="#231F20"></path></svg></div>
              </div>
            </div>
            <app-step-summary formGroupName="stepSummary" [application]="application"></app-step-summary>
          </mat-step>
        </mat-stepper>

      <!-- Buttons -->
      <div class="row mt-4 mb-5">

        <!-- Next Button -->
        <div class="col-6 d-flex" *ngIf="stepper.selectedIndex < 3">
          <div>
            <button (click)="goForward()" type="button" class="btn btn-common btn-yellow btn btn-secondary">Next</button>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="col-6 d-flex"  *ngIf="stepper.selectedIndex == 3">
          <div>
            <button (click)="submit()" type="button" class="btn btn-common btn-yellow btn btn-secondary">Submit</button>
          </div>
        </div>
      </div>
      
    </ng-container>
  </div>
  </div>
</div>

<pre style="margin-top:50px; border: 1px solid red; width: fit-content">
  {{ application | json }}
</pre>


<pre style="margin-top:50px; border: 1px solid red; width: fit-content">
  {{ applicationForm.value | json }}
</pre>