import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-tracker',
  templateUrl: './step-tracker.component.html',
  styleUrl: './step-tracker.component.scss'
})
export class StepTrackerComponent {

  @Input() stepData: Array<{ name: string; completed:boolean}> = []

  constructor() {}

}
