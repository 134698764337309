<nav class="landing-banner">
    <!-- Wizard Header Image -->
    <div class="container d-flex align-items-center">
      <div class="sc-fqkvVR hYcZyZ" type="fadeIn" direction="normal">
        <div class="banner-bg" style="background-image: url('/assets/static/media/article-4-option-3-1785111.c672f634.png');"></div>
      </div>
      <div class="banner-header-text d-flex flex-column justify-content-end col-md-12">
        <div class="d-sm-block d-md-none">
          <div class="sc-fqkvVR llwXDb" type="fadeInFromLeft" direction="normal">
            <h1 class="banner-header-title">Let’s start with your ID number</h1>
          </div>
          <div class="sc-fqkvVR bzZdWE" type="fadeInFromLeft" direction="normal">
            <div class="banner-header-content">We’ll use your 13-digit ID number to verify your identity</div>
          </div>
        </div>
        <div class="d-none d-sm-none d-md-block">
          <div class="sc-fqkvVR dRmBcj" type="popIn" direction="normal">
            <h1 class="banner-header-title">Let’s start with your ID number</h1>
          </div>
          <div class="sc-fqkvVR dEoDx" type="fadeInFromBottom" direction="normal">
            <div class="banner-header-content">We’ll use your 13-digit ID number to verify your identity</div>
          </div>
        </div>
      </div>
    </div>
</nav>

  <!-- Stepper contect -->
  <div class="container pl-container-page">
    <div class="container" style="margin-top: 20px;">
      <div class="row">
          <div class="col-md-12">
              <h2 class="" style="font-size: 24px; margin-top: 0px; color: #15305D">How to Pre-Enrol.</h2>
              <!--Tabs details-->
              <div class="container" style="margin-top: 20px; padding-top: 20px; border: 1px solid #15305D; border-radius: 10px;">
          <ul class="nav nav-tabs" id="myTabs" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="step1-tab" data-bs-toggle="tab" href="#step1" style="background-color: #15305D; color: white;" aria-selected="true" role="tab">Step 1</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="step2-tab" data-bs-toggle="tab" href="#step2" style="background-color: #1b4087; color: white;" aria-selected="false" role="tab" tabindex="-1">Step 2</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="step3-tab" data-bs-toggle="tab" href="#step3" style="background-color: #214fb1; color: white;" aria-selected="false" role="tab" tabindex="-1">Step 3</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="step4-tab" data-bs-toggle="tab" href="#step4" style="background-color: #2780d8; color: white;" aria-selected="false" role="tab" tabindex="-1">Step 4</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="step5-tab" data-bs-toggle="tab" href="#step5" style="background-color: #29a2e2; color: white;" aria-selected="false" role="tab" tabindex="-1">Step 5</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="step6-tab" data-bs-toggle="tab" href="#step6" style="background-color: #2ccaf3; color: white;" aria-selected="false" role="tab" tabindex="-1">Step 6</a>
            </li>
          </ul>
                      <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade active show" id="step1" role="tabpanel" aria-labelledby="#step1-tab">
                              <h2>Pre-Enrol Online</h2>
                              <p>Our simple pre-enrol process is designed to set you up for success. <a href="https://www.eduvos.com/pre-enrol/" target="_blank">Pre-enrol online</a>    
                              and pay your R300 pre-enrolment fee to start your Eduvos learning journey. You will need
                                  to upload a copy of your ID, your parent/sponsor ID and your latest academic results.
                              </p>
                              <br>
                          </div>
                          <div class="tab-pane fade" id="step2" role="tabpanel" aria-labelledby="#step2-tab">
                              <h2>Acceptance</h2>
                              <p>
                                  Eduvos will review your pre-enrolment to confirm you meet entry requirements. We will
                                  then send you a quote relevant to your qualification of choice.
                              </p>
                          </div>
                          <div class="tab-pane fade" id="step3" role="tabpanel" aria-labelledby="#step3-tab">
                              <h2>1st Fee Instalment</h2>
                              <p>To finalise your enrolment with Eduvos, please pay your 1st fee
                                  instalment or make an upfront payment using our
                                      <a href="https://www.eduvos.com/paygate/" target="_blank"> online payment method</a>.
                               
                              Proof of payment (POP) can be sent to <a href="mailto:payments@eduvos.com">payments&#64;eduvos.com</a>. Please use your student
                                  number or ID number as your payment reference.</p>
                          </div>
                          <div class="tab-pane fade" id="step4" role="tabpanel" aria-labelledby="#step4-tab">
                              <h2>Submit Final Results</h2>
                              <p>In January, when you have your final results, please email your Student Affairs Advisor
                                  (SAA) to finalise your enrolment. </p>
                          </div>
                           <div class="tab-pane fade" id="step5" role="tabpanel" aria-labelledby="#step5-tab">
                              <h2>Orientation &amp; Systems Access</h2>
                              <p>Once you’re fully enrolled with Eduvos, you will receive your orientation information,
                                  timetable, access to myLMS and Office 365 account. </p>
                          </div>
                         <div class="tab-pane fade" id="step6" role="tabpanel" aria-labelledby="#step6-tab">
                              <h2>Your Journey Begins</h2>
                              <p>Classes start in February 2025 – see you on campus!</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>