<!-- Dialog Content -->
<div class="container">
    <div class="otp-popup-content">
    
      <div class="row">
        <!-- Header -->
        <div class="col-12">
          <h1>Secure OTP Verification</h1>
        </div>
        
        <!-- OTP Instructions -->
        <div class="col-12">
          <p>Enter the one-time password (OTP) that was sent to your registered mobile number
            <strong>({{ data.contact_number }})</strong>
          </p>
        </div>
        
        <!-- OTP Input Field -->
        <div class="col-12">
            <form [formGroup]="otpForm" (ngSubmit)="validateOtp()">
            <label for="employer_start_month">Enter the 4-digit OTP:</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput formControlName="otp" placeholder="Enter OTP" maxlength="4">
                <mat-error *ngIf="otpForm.get('otp')?.hasError('required')">OTP is required</mat-error>
                <mat-error *ngIf="otpForm.get('otp')?.hasError('minlength')">OTP must be 4 digits</mat-error>
                <mat-error *ngIf="otpForm.get('otp')?.hasError('maxlength')">OTP must be 4 digits</mat-error>
                <mat-error *ngIf="otpForm.get('otp')?.hasError('invalidOtp')">{{errorMessage}}</mat-error>
                
              </mat-form-field>

              <!-- Submit Button -->
              <div class="col-12 mb-3">
                <button class="btn btn-common btn-yellow" type="submit">Submit</button>
              </div>
            </form>
        
        </div>
        
        <!-- Help Section -->
        <div class="col-12">
          <h2 class="">Didn’t get the OTP?</h2>
        </div>
            
        <div class="col-12" *ngIf="timer > 0">
            <p matListItemTitle>You can request a new OTP in <b>{{ timer }} </b> seconds.</p>
        </div>
        <div class="col-12" *ngIf="timer <= 0">
            <p class="a" (click)="resetOtp()">Resend OTP</p>
        </div>

        <div  class="col-12">
          <p>Please <a href="contact-url">contact us</a> if you're having trouble.</p>
        </div>
      </div>
    
    </div>
</div>
