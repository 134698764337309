import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormBuilder, Validators, FormsModule, ReactiveFormsModule, FormGroupDirective} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import {MatListModule} from '@angular/material/list';


import { WizardComponent } from './components/wizard/wizard.component';
import { ApplicantComponent } from './components/step/applicant/applicant.component';
import { ApplicationComponent } from './components/application/application.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { AffordabilityComponent } from './components/step/affordability/affordability.component';
import { AmountComponent } from './components/step/amount/amount.component';
import { SummaryComponent } from './components/step/summary/summary.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BannerComponent } from './components/banner/banner.component';
import { HttpClientModule } from '@angular/common/http';
import { OfferApi } from './api/offerApi';
import { DocumentsUploadComponent } from './components/documents-upload/documents-upload.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CompletedComponent } from './components/completed/completed.component';
import { CreateLeadComponent } from './components/create-lead/create-lead.component';
import { CURRENCY_MASK_CONFIG, CurrencyMaskConfig, CurrencyMaskModule } from 'ng2-currency-mask';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { StatusCheckComponent } from './components/status-check/status-check.component';
import { SignPreagreementComponent } from './components/sign-preagreement/sign-preagreement.component';
import { OtpDialogContentComponent, OtpVerificationComponent } from './components/otp-verification/otp-verification.component';
import { SessionService } from './services/session.service';
import { MatDialogModule } from '@angular/material/dialog';
import { DndDirective } from './helpers/dnd.directive';
import { PopoverDirective } from './directives/popover.directive';
import { PopoverService } from './services/popover.service';
import { PopoverContainerComponent } from './components/popover-container/popover-container.component';
import { StepTrackerComponent } from './components/step-tracker/step-tracker.component';
import { TimelineComponent } from './components/timeline/timeline.component';



export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  decimal: ".",
  precision: 2,
  prefix: "R",
  suffix: "",
  thousands: " "
};


@NgModule({
  declarations: [
    AppComponent,

    //
    FooterComponent,
    HeaderComponent,

    // 
    ProgressBarComponent,
    DocumentsUploadComponent,
    CompletedComponent,
    CreateLeadComponent,
    ApplicationComponent,
    BannerComponent,

    // 
    WizardComponent,
    
    // Steps
    AffordabilityComponent,
    AmountComponent,
    ApplicantComponent,
    
    SummaryComponent,
    DocumentsUploadComponent,
    ProgressBarComponent,
    CompletedComponent,
    StatusCheckComponent,
    SignPreagreementComponent,
    OtpVerificationComponent,
    OtpDialogContentComponent,
    DndDirective,
    PopoverDirective,
    PopoverContainerComponent,
    StepTrackerComponent,
    TimelineComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatStepperModule,
    ReactiveFormsModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatCardModule,
    MatCheckboxModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    CurrencyMaskModule,
    RecaptchaModule,
    MatIconModule,
    MatDialogModule,
    MatListModule,
    SweetAlert2Module.forRoot(),
  ],
  exports: [PopoverDirective],
  providers: [
    provideClientHydration(),
    FormGroupDirective,
    OfferApi,
    SessionService,
    PopoverService,
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptcha.siteKey } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
