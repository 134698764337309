import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Application } from '../../../models/application';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { WizardComponent } from '../../wizard/wizard.component';

@Component({
  selector: 'app-step-applicant',
  templateUrl: './applicant.component.html',
  styleUrl: './applicant.component.scss'
})
export class ApplicantComponent implements OnInit {
  @Input() application: Application;
  @Input() formGroupName: string;

  form: FormGroup;

  months: {name: string, value: number}[] = [
    { name: 'January', value: 1 },
    { name: 'February', value: 2 },
    { name: 'March', value: 3 },
    { name: 'April', value: 4 },
    { name: 'May', value: 5 },
    { name: 'June', value: 6 },
    { name: 'July', value: 7 },
    { name: 'August', value: 8 },
    { name: 'September', value: 9 },
    { name: 'October', value: 10 },
    { name: 'November', value: 11 },
    { name: 'December', value: 12 }
  ];

  provinces: string[] = [
    'Gauteng', 'Western Cape', 'KwaZulu-Natal', 'Eastern Cape', 'Free State',
    'Limpopo', 'Mpumalanga', 'North West', 'Northern Cape'
  ];
  bankNames: string[] = ['FNB', 'ABSA'];
  accountTypes: string[] = ['Savings', 'Current'];

  selectedEmploymentStatus: string;
  years: number[] = [];
  dates: number[] = [];

  constructor(
    private rootFormGroup: FormGroupDirective,
    private wizardComponent: WizardComponent,
    private cdr: ChangeDetectorRef
  ) {
    
  }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;

    const startYear = 1980;
    const currentYear = new Date().getFullYear();
    
    for (let year = startYear; year <= currentYear; year++) {
      this.years.push(year);
    }

    for (let date = 1; date <= 31; date++) {
      this.dates.push(date);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.application && changes.application.currentValue && this.form) {
      const employmentStatus = this.application.offer.employment_status;
      this.form.get('employment_status').setValue(employmentStatus);
      this.selectedEmploymentStatus = employmentStatus;
    }
  }

  onEmploymentStatusChange(status: string): void {
    this.selectedEmploymentStatus = status;
    this.form.get('employment_status').setValue(status);
  }
}
