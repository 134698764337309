export const environment = {
  title: 'Eduvos Extender',
  env_name: 'dev',
  debug: true,
  production: false,
  url: 'https://dev.api.smartfunder.co.za',
  recaptcha: {
    siteKey: '6LeTvyUqAAAAAAthgO8QOT301oSa-dZsgRdTusqg',
  },
};
