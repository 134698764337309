import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferApi } from '../../api/offerApi';
import { Application, SubStatusIdentifier } from '../../models/application';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss']
})
export class DocumentsUploadComponent implements OnInit {

  fileTypes = [
    {
      name: 'Applicant ID',
      title: 'Copy of your ID or SmartCard',
      description: 'Ensure that you upload a clear copy of your ID document. If you are uploading a SmartCard, please include a copy of the front and back.',
      warning: ''
    },
    {
      name: '3 months bank statement',
      title: 'Latest 3-month bank statement',
      description: 'We require the most recent 3-month’s bank generated PDF statements from the bank that you receive your salary in.',
      warning: ''
    },
  ];

  bannerData: Array<{ title: string, description: string }> = [
    { title: 'Documents needed', description: 'We require the following documents to complete your application process.' },
  ];

  currentStep = 0;
  files: { [key: string]: any[] } = { 'Applicant ID': [], '3 months bank statement': [] };
  application: Application = new Application();

  readonly maxFiles = {
    'Applicant ID': 2,
    '3 months bank statement': 3
  };

  constructor(
    public wizardService: WizardService,
    private router: Router,
    private route: ActivatedRoute,
    private offerApi: OfferApi,
  ) { }

  ngOnInit(): void {
    const unique_id = this.route.snapshot.paramMap.get('unique_id');
    if (unique_id) {
      this.getPreFilledLead(unique_id);
    }
  }

  private getPreFilledLead(unique_id: string): void {
    this.offerApi.getLeadFromIdentifier(unique_id).subscribe({
      next: (res) => {
        if (res.success) {
          this.application = res.data;
          this.wizardService.confirm_correct_page('documents', this.application);
        }
      },
      error: (error) => {
        console.error('Error fetching pre-filled lead:', error);
      }
    });
  }

  submit() {
    this.application.sub_status.identifier = SubStatusIdentifier.in_progress_pending_verification;
    this.offerApi.saveApplication(this.application).subscribe({
      next: (res) => {
        if (res.success) {
          console.log('Application submitted successfully:', res.data);
          this.router.navigate(['/completed', this.application.identifier]);
        } else {
          console.error('Error submitting application:', res.message);
        }
      },
      error: (error) => {
        console.error('Error submitting application:', error);
      }
    });
  }

  onFileDropped($event: any, fileType: string) {
    this.prepareFilesList($event, fileType);
  }

  fileBrowseHandler(inputElement: HTMLInputElement, fileType: string) {
    console.log('fileBrowseHandler:', inputElement.files);
    const files = inputElement.files;
    this.prepareFilesList(files, fileType);
  }

  deleteFile(fileType: string, index: number) {
    this.files[fileType].splice(index, 1);
  }

  prepareFilesList(files: FileList, fileType: string) {

    if (this.files[fileType].length + files.length > this.maxFiles[fileType]) {
      console.warn(`You can upload a maximum of ${this.maxFiles[fileType]} files for ${fileType}.`);
      this.fileTypes.find(file => file.name === fileType).warning = `You can upload a maximum of ${this.maxFiles[fileType]} files.`;
      return;
    }
    for (const file of Array.from(files)) {
      file['progress'] = 0;
      // file.progress = 0;
      this.files[fileType].push(file);
    }
    this.uploadFiles(fileType);
  }

  uploadFiles(fileType: string) {
    const filesToUpload = this.files[fileType];
    filesToUpload.forEach(file => {
      if (file.progress !== 100) {
        this.offerApi.uploadSupportingDocuments(this.application, fileType, file).subscribe(
          (progress: number) => {
            file.progress = progress;
          },
          (error) => {
            console.error('Error uploading file:', error);
            file.progress = 100;
          }
        );
      }
    });
  }

  formatBytes(bytes: number, decimals: number = 2): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

}
