
<app-banner [currentStep]="currentStep" [data]="bannerData"></app-banner>

<!-- OTP Verification Component -->
<app-otp-verification [identifier]="identifier" (otpSuccess)="handleOtpSuccess($event)"></app-otp-verification>


<div *ngIf="isLoading" class="d-flex justify-content-center align-items-center" style="height: 40rem;">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div  *ngIf="!isLoading" class="container my-4">

    <!-- Header -->
    <div class="text-center">
        <h1 class="">LOAN AGREEMENT</h1>
        <h1 class="">PRE-AGREEMENT STATEMENT AND QUOTATION</h1>
        <h5 class="">In terms of section 92 of the National Credit Act 34 of 2005</h5>
    </div>

    <!-- First table -->
    <div class="row">
        <div class="col-md-12">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">DETAILS OF CREDIT PROVIDER</th>
                        <th scope="col">DETAILS OF CONSUMER / applicant</th>
                    </tr>
                    <td><b>Name:</b> {{ creditProvider.name }}</td>
                    <td><b>Name:</b> {{ application.applicant.first_name }} {{ application.applicant.last_name }}</td>
                    <tr>
                        <td>
                            <b>NCRCP Number:</b> {{ creditProvider.ncrcpNumber }} <br>
                            <b>Registration Number:</b> {{ creditProvider.registration }}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><b>Physical Address:</b> {{ creditProvider.address }}</td>
                        <td><b>Address:</b> {{ application.offer.address_street }} {{ application.offer.address_suburb }} {{ application.offer.address_city }} {{ application.offer.address_province }} {{ application.offer.address_postal_code }}</td>
                    </tr>
                    <tr>
                        <td><b>Contact Details:</b> {{ creditProvider.contact }}</td>
                        <td><b>Contact Details:</b> {{ application.applicant.contact_number }}</td>
                    </tr>
                    <tr>
                        <td><b>Application Reference Number:</b> {{ creditProvider.registration }}</td>
                        <td><b>ID Number:</b> {{ application.applicant.id_number }}</td>
                    </tr>

                </thead>
            </table>
        </div>
    </div>

    <!-- Header -->
    <div class="row">
        <div class="col-md-12">
            <div class="text-center mt-5">
                <h1>Quotation</h1>
                <h3>Part A: Summary</h3>
            </div>
        </div>
    </div>

    <!-- Quotation Summary -->
    <div class="row">
        <div class="col-md-12">
            <table class="table table-bordered">
                <tbody>
                    <tr>
                    <th scope="row">Principal Debt (Loan amount)</th>
                    <td>{{ application.loan_amount | currency:'R':'symbol'}}</td>
                    </tr>
                    <tr>
                    <th scope="row">Instalment, including interest, fees, and required insurance (credit life insurance)</th>
                    <td>{{ application.monthly_amount | currency:'R':'symbol' }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Initiation Fee*</th>
                    <td>{{ quotationSummary.initiationFee }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Number of instalments</th>
                    <td>{{ quotationSummary.numberOfInstalments }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Monthly service fee, included in instalment</th>
                    <td>{{ quotationSummary.monthlyServiceFee }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Frequency of instalment payments</th>
                    <td>{{ quotationSummary.frequency }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Annual Interest Rate</th>
                    <td>{{ quotationSummary.annualInterestRate }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Monthly Interest Rate</th>
                    <td>{{ quotationSummary.monthlyInterestRate }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Total Cost of Credit</th>
                    <td>{{ application.loan_amount * 1.23 | currency:'R':'symbol'}}</td>
                    </tr>
                    <tr>
                    <th scope="row">Rand value of the interest</th>
                    <td>{{ quotationSummary.randValueOfInterest }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Cost of required credit life insurance included in instalment</th>
                    <td>{{ quotationSummary.costOfCreditLifeInsurance }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Credit cost multiple</th>
                    <td>{{ quotationSummary.creditCostMultiple }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Monthly instalment amount</th>
                    <td>{{ application.monthly_amount | currency:'R':'symbol'}}</td>
                    </tr>
                    <tr>
                    <th scope="row">Number of instalments</th>
                    <td> 24 </td>
                    </tr>
                    <tr>
                    <th scope="row">Monthly instalment due date</th>
                    <td>{{ repaymentArrangement.monthlyInstalmentDueDate }}</td>
                    </tr>
                    <tr>
                    <th scope="row">First instalment due date</th>
                    <td>{{ repaymentArrangement.firstInstalmentDueDate }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Method of payment</th>
                    <td>{{ repaymentArrangement.methodOfPayment }}</td>
                    </tr>
                    <tr>
                    <th scope="row">Debit Order deduction date</th>
                    <td>{{ repaymentArrangement.debitOrderDeductionDate }}</td>
                    </tr>
                </tbody>
                </table>
        
                <p class="mt-3"><strong>{{ repaymentArrangement.initiationFeeNote }}</strong></p>
                <p>{{ repaymentArrangement.quotationValidity }}</p>
        </div>
    </div>

    <!-- Header -->
    <div class="row">
        <div class="col-md-12">
            <div class="text-center mt-5">
                <h1 class="">PRE-AGREEMENT STATEMENT</h1>
            </div>
        </div>
    </div>

    <!-- Pre-agreement statement -->
    <div class="row">
        <div class="col-md-12 border" style="max-height: 300px; overflow-y: auto; padding: 15px;">

            <p>1. GENERAL</p>
            <p><strong>1.1. PLEASE ENSURE THAT YOU CAREFULLY READ AND FULLY UNDERSTAND YOUR RIGHTS AND OBLIGATIONS SET OUT IN THIS PRE- AGREEMENT STATEMENT AND THE LOAN AGREEMENT TERMS AND CONDITIONS BEFORE YOU ACCEPT ANY TERMS OF YOUR QUOTATION OR ENTER INTO THE LOAN AGREEMENT.</strong></p>
            <p><strong>1.2. IF YOU REQUIRE AN EXPLANATION OR CLARIFICATION OF ANY TERMS SET OUT IN YOUR QUOTATION, THE PRE-AGREEMENT STATEMENT OR THE LOAN AGREEMENT TERMS AND CONDITIONS, PLEASE CONTACT TYME BANK CUSTOMER CARE ON 0860 999 119</strong></p>
            <p>2. DEFINITIONS</p>
            <p><strong>Business Days</strong><br>means any days other than a Saturday, Sunday, or a statutory holiday in the Republic of South Africa, and "Business Day" shall have a corresponding meaning.</p>
            <p><strong>Credit Cost Multiple</strong><br>means the Total Cost of Credit divided by the Loan Amount.</p>
            <p><strong>Credit Life Insurance</strong><br>means the required insurance cover payable in the event of Your / the applicant’s death, disability, retrenchment, or other insurable risk that is likely to impair Your / the applicant’s ability to earn an income or meet the obligations under the Loan Agreement as contemplated in paragraph 8.</p>
            <p><strong>Credit Provider</strong><br>means Tyme Bank Limited (registration number: 2015/231510/06), a registered credit provider (NCRCP:10774).</p>
            <p><strong>Disburse / Disbursement</strong><br>means the payment of the Loan Amount into Your Nominated Bank Account.</p>
            <p><strong>Disbursement Conditions</strong><br>means the conditions set out in paragraph 4 of the Pre-Agreement Statement that must be fulfilled in order for the Loan Amount to be Disbursed.</p>
            <p><strong>Disbursement Date</strong><br>means the date on which the Loan Amount is Disbursed into Your Bank Account, subject to the fulfillment of the Disbursement Conditions.</p>
            <p><strong>EveryDay Account</strong><br>means, if You are an existing customer of TymeBank, Your verified TymeBank account.</p>
            <p><strong>Initiation Fee</strong><br>means the cost of performing the necessary services to initiate the Loan Agreement which is payable by You upon entering into the Loan Agreement as further explained in paragraph 5.1.2.</p>
            <p><strong>Initiation Fee Payment Account</strong><br>means the Tyme Bank account into which the Initiation Fee must be paid should You choose to pay the Initiation Fee upfront in terms of paragraph 5.1.2.</p>
    
            <h6><strong>Loan / Loan Amount</strong></h6>
            <p>means the principal debt amount advanced to You under the terms of this Loan Agreement.</p>
            <h6><strong>Loan Agreement / Agreement</strong></h6>
            <p>means this Pre-Agreement Statement, Your Quotation or updated Quotation (where applicable), and Your Loan Application read together with Loan Agreement terms and conditions (excluding any indicatory offers made to You prior to the submission of Your Loan Application).</p>
            <h6><strong>Loan Application</strong></h6>
            <p>means the loan application process undertaken by You through the Tyme Bank digital channels (mobile app and website) and all such information provided by You as part of this process.</p>
            <h6><strong>NCA / National Credit Act</strong></h6>
            <p>National Credit Act, 34 of 2005.</p>
            <h6><strong>Nominated Bank Account</strong></h6>
            <p>means the bank account nominated by You in Your Loan Application as being the bank account into which the Loan will be Disbursed.</p>
            <h6><strong>Outstanding Loan Amount</strong></h6>
            <p>means the outstanding amount due and payable by You under Your Loan Agreement from time to time.</p>
            <h6><strong>Personal Information</strong></h6>
            <p>has the meaning provided in the POPIA and includes (i) information provided by You to Us during Your Loan Application process; and (ii) information regarding the performance of Your obligations under Your Loan Agreement (e.g., the repayment of Your monthly instalments).</p>
            <h6><strong>Pre-Agreement Statement</strong></h6>
            <p>means this pre-agreement disclosure statement provided to You in terms of section 92 of the National Credit Act.</p>
            <h6><strong>Primary Bank / Primary Bank Account</strong></h6>
            <p>means the bank account that Your salary / income is paid into and from which Your monthly instalment will be deducted.</p>
            <h6><strong>Privacy Policy</strong></h6>
            <p>means Our privacy policy which explains how We will process and store your Personal Information and can be accessed from Our website (www.tymebank.co.za).</p>
            <h6><strong>POPIA</strong></h6>
            <p>means Protection of Personal Information Act number 4 of 2013 as amended from time to time.</p>
            <h6><strong>Quotation</strong></h6>
            <p>means the initial and updated quotation (where applicable) provided to You in terms of section 92 of the National Credit Act.</p>
            <h6><strong>Salary Day</strong></h6>
            <p>means the day in the month (e.g., the 25th day of the month) on which Your salary is paid into Your Primary Bank Account which information may be obtained or confirmed by You during the Loan Application process.</p>
            <h6><strong>“TFG”</strong></h6>
            <p>means Foschini Retail Group (Pty) Ltd.</p>
            <h6><strong>TFG Money Personal Loan Protection Policy</strong></h6>
            <p>means the required credit life insurance policy arranged by Us (the Credit Provider) and underwritten by Guardrisk Life Limited (registration number 1999/013922/06 and FSP number 76), an authorised financial services provider and an insurer licensed to conduct life insurance business in terms of the Insurance Act 18 of 2017.</p>
            <h6><strong>“Total Cost of Credit”</strong></h6>
            <p>means the total of the Loan Amount, interest, fees, and charges as set out in Your Quotation which makes up the total cost of Your Loan.</p>
            
            <h5><strong>3. “We” / “Us” / “TymeBank”</strong></h5>
            <p>means Tyme Bank Limited, the Credit Provider.</p>
            <h5><strong>QUOTATION</strong></h5>
            <p>3.1. This Pre-Agreement Statement should be read together with Your Quotation. Your Quotation sets out the Total Cost of Credit of Your Loan as well as other important information specific to You.</p>
            <p>3.2. Your Quotation is valid for 5 (five) Business Days from the date on which the Quotation is presented to You. Once accepted, Your Loan Agreement will be conditional upon You fulfilling the Disbursement Conditions set out in paragraph 4 below. We will only Disburse Your Loan after You have fulfilled the Disbursement Conditions.</p>
            <p>3.3. Should You fail to fulfill the Disbursement Conditions within 5 (five) Business Days of the Quotation being presented, We have the right (but are not obliged) to withdraw or recalculate Your Quotation which may result in a higher Total Cost of Credit.</p>
            <p>3.4. On the acceptance of Your Quotation and provided that You fulfill Your Disbursement Conditions within 5 (five) Business Days of receiving Your Quotation, the Total Cost of Credit of Your Loan Agreement will be either the same Total Cost of Credit set out in Your Quotation or at lower Total Cost of Credit. If the Total Cost of Credit of Your Loan changes from the amount set out in Your initial Quotation, We will provide You with an updated Quotation.</p>
            
            <h5><strong>4. DISBURSEMENT OF YOUR LOAN AND THE DISBURSEMENT CONDITIONS</strong></h5>
            <p>Your Loan Amount will only be Disbursed into Your Nominated Bank Account after You have fulfilled the following Disbursement Conditions:</p>
            <p><strong>“You” / “Your” / “applicant”</strong><br>means You, being the applicant that enters into the Loan Agreement with TymeBank and who is 18 years or older.</p>
            <p>4.1.1. Approve the DebiCheck Debit Order Mandate: as further explained in paragraph 7.2 below, You must grant, authenticate and approve a DebiCheck Debit Order Mandate in order for Us to deduct Your Monthly Instalments from your Primary Bank Account. This Disbursement Condition will only be fulfilled once We receive confirmation from Your Primary Bank that You have approved the DebiCheck Debit Order Mandate.</p>
            <p>4.1.2. Pay the Initiation Fee (if charged upfront): should You choose to pay the Initiation Fee upfront (as explained in paragraph 5.1.2 below), You must pay the Initiation Fee into the Initiation Fee Payment Account and provide us with proof of such payment. The Initiation Fee Payment Account details and further information on the process that must be followed in order for You to make this payment will be sent to You once You have submitted Your Loan Application.</p>
            <p>4.1.3. Information verification: We must confirm and verify that the information provided by You in Your Loan Application is true and correct. Should any information provided by You during Your Loan Application process be found to be incorrect, We reserve the right to reject Your Loan Application and terminate the Loan Agreement.</p>
            <p>4.1.4. Fulfill the specific approval conditions: You must fulfill the specific approval conditions that we may notify You of after you have submitted your Loan Application. This may include the requirement to provide us with certain documentation (e.g., proof of Your income) and satisfy other specific requirements that we may inform you of during the Loan Application process.</p>
        </div>
    </div>

    <!-- SIGN AGREEMENT -->
    <div class="row">
        
        <div class="col-md-12">
            <div class="text-center mt-5">
                <h1 class="">SIGN AGREEMENT</h1>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-check mt-3">
                <input class="form-check-input" type="checkbox" id="agreeCheckbox" required>
                <label class="form-check-label" for="agreeCheckbox">
                    I agree to the terms and conditions.
                </label>
            </div>
        </div>

        <!-- Submit Button -->
        <div class="col-12 d-flex justify-content-center">
          <div>
            <button (click)="submit()" type="button" class="btn btn-common btn-yellow btn btn-secondary">Submit</button>
          </div>
        </div>
    </div>
</div>
  
