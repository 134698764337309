import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AmountComponent } from '../step/amount/amount.component';
import { ApplicantComponent } from '../step/applicant/applicant.component';
import { AffordabilityComponent } from '../step/affordability/affordability.component';
import { SummaryComponent } from '../step/summary/summary.component';
import { Application, SubStatusIdentifier } from '../../models/application';
import { WizardService } from '../../services/wizard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferApi } from '../../api/offerApi';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(AmountComponent) Step1AmountComponent: AmountComponent;
  @ViewChild(ApplicantComponent) Step3ApplicantComponent: ApplicantComponent;
  @ViewChild(AffordabilityComponent) Step4AffordabilityComponent: AffordabilityComponent;
  @ViewChild(SummaryComponent) Step5SummaryComponent: SummaryComponent;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild(OtpVerificationComponent) otpVerificationComponent!: OtpVerificationComponent;

  isLoading: boolean = true;
  otpVerified: boolean = false;
  identifier: string;

  bannerData: Array<{ title: string, description: string }> = [
    { title: 'Confirm your details',description: 'Please check that the details provided are correct',},
    { title: 'Tell us about yourself', description: "We'll keep your personal info secure at all times",},
    { title: "Let's check your affordability", description: "We'll just need your income and expense information",},
    { title: 'Review your application', description: 'If any of this information is incorrect, you can go back and update it before submitting your application',},
  ];

  applicationForm: FormGroup;
  application: Application = new Application();
  SubStatusIdentifier = SubStatusIdentifier;

  loading = true;

  constructor(
    public wizardService: WizardService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private offerApi: OfferApi,
  ) { }

  ngOnInit(): void {
    this.identifier = this.route.snapshot.paramMap.get('unique_id');
  }

  ngAfterViewInit(): void {
  }

  handleOtpSuccess(success: boolean): void {
    if (success) {
      
      this.getPreFilledLead(this.identifier);
    } else {
      this.router.navigate(['/completed', '']);
    }
  }

  ngOnDestroy(): void {}

  initForm() {
    this.applicationForm = this.fb.group({
      stepAmount: this.fb.group({
      }),
      stepApplicant: this.fb.group({
        ethnicity: new FormControl('', Validators.required),
        employer_start_month: new FormControl('', [Validators.required, Validators.pattern('^(0?[1-9]|1[0-2])$')]),
        employer_start_year: new FormControl('', [Validators.required, Validators.pattern('^(19[8-9][0-9]|20[0-9]{2})$')]),
        employer_name: new FormControl('', Validators.required),
        employment_status: new FormControl('', Validators.required),
        salary_date: new FormControl('', [Validators.required, Validators.pattern('^(0?[1-9]|[12][0-9]|3[01])$')]),

        address_street: new FormControl('', Validators.required),
        address_suburb: new FormControl('', Validators.required),
        address_city: new FormControl('', Validators.required),
        address_province: new FormControl('', Validators.required),
        address_postal_code: new FormControl('', Validators.required),

        bank_name: new FormControl('', Validators.required),
        account_number: new FormControl('', Validators.required),
        account_type: new FormControl('', Validators.required),
      }),
      stepAffordability: this.fb.group({
        gross_monthly_income: new FormControl([Validators.required, Validators.min(0)]),
        net_monthly_income: new FormControl( [Validators.required, Validators.min(0)]),
        total_monthly_credit_expenses: new FormControl([Validators.required, Validators.min(0)]),
        total_monthly_living_expenses: new FormControl([Validators.required, Validators.min(0)]),
      }),
      stepSummary: this.fb.group({
        credit_check_consent: [],
        recaptcha: [],
      }),
    });
  }

  private getPreFilledLead(unique_id: string): void {
    this.offerApi.getLeadFromIdentifier(unique_id).subscribe({
      next: (res) => {
        if (res.success) {
          
          this.application = res.data;
          this.initForm();
          this.isLoading = false;

          this.cdr.detectChanges();
          this.updateFormValueFromModel(); // Update the form with pre-filled data
          
          this.calculateWizardAndSteps();
  
        } else {
          this.handleError(res.message, '1');
        }
      },
      error: (error) => {
        this.handleError(error, '2');
        if (error.status === 401) {
          this.otpVerificationComponent.checkOtpRequirement();
        }
      },
      complete: () => {
      }
    });
  }


  private handleError(message: string, errorCode: string): void {
    console.log(`Pre-fill error [${errorCode}]: ${message}`);
    // this.wizardService.errorAlert(message, errorCode);
    // throw new Error(`Pre-fill error [${errorCode}]: ${message}`);
  }

  updateFormValueFromModel() {
    this.applicationForm.patchValue({
      stepAmount: {
        first_name: this.application.applicant.first_name,
        last_name: this.application.applicant.last_name,
        email: this.application.applicant.email,
        contact_number: this.application.applicant.contact_number,
        id_number: this.application.applicant.id_number,
        loan_amount: this.application.loan_amount,
        monthly_amount: this.application.monthly_amount,
      },
      stepApplicant: {
        ethnicity: this.application.applicant.ethnicity,
        employer_start_month: this.application.offer.employer_start_month,
        employer_start_year: this.application.offer.employer_start_year,
        employer_name: this.application.offer.employer_name,
        employment_status: this.application.offer.employment_status,
        salary_date: this.application.offer.salary_date,
        address_street: this.application.offer.address_street,
        address_suburb: this.application.offer.address_suburb,
        address_city: this.application.offer.address_city,
        address_province: this.application.offer.address_province,
        address_postal_code: this.application.offer.address_postal_code,

        bank_name: this.application.offer.bank_name,
        account_number: this.application.offer.account_number,
        account_type: this.application.offer.account_type,
      },
      stepAffordability: {
        gross_monthly_income: this.application.offer.gross_monthly_income,
        net_monthly_income: this.application.offer.net_monthly_income,
        total_monthly_credit_expenses: this.application.offer.total_monthly_credit_expenses,
        total_monthly_living_expenses: this.application.offer.total_monthly_living_expenses,
      },
      stepSummary: {
        credit_check_consent: '',
        recaptcha: '',
      },
    });
  }

  updateModelFromFormValue() {
    const currentStep = this.stepper.selectedIndex;
    // if (currentStep === 0) {
      this.application.applicant.first_name = this.applicationForm.value.stepAmount.first_name;
      this.application.applicant.last_name = this.applicationForm.value.stepAmount.last_name;
      this.application.applicant.email = this.applicationForm.value.stepAmount.email;
      this.application.applicant.contact_number = this.applicationForm.value.stepAmount.contact_number;
      this.application.applicant.id_number = this.applicationForm.value.stepAmount.id_number;
      this.application.loan_amount = this.applicationForm.value.stepAmount.loan_amount;
      this.application.monthly_amount = this.applicationForm.value.stepAmount.monthly_amount;
    // } else if (currentStep === 1) {
      this.application.applicant.ethnicity = this.applicationForm.value.stepApplicant.ethnicity;
      this.application.offer.employer_start_month = this.applicationForm.value.stepApplicant.employer_start_month;
      this.application.offer.employer_start_year = this.applicationForm.value.stepApplicant.employer_start_year;
      this.application.offer.employer_name = this.applicationForm.value.stepApplicant.employer_name;
      this.application.offer.employment_status = this.applicationForm.value.stepApplicant.employment_status;
      this.application.offer.salary_date = this.applicationForm.value.stepApplicant.salary_date;

      this.application.offer.address_street = this.applicationForm.value.stepApplicant.address_street;
      this.application.offer.address_suburb = this.applicationForm.value.stepApplicant.address_suburb;
      this.application.offer.address_city = this.applicationForm.value.stepApplicant.address_city;
      this.application.offer.address_province = this.applicationForm.value.stepApplicant.address_province;
      this.application.offer.address_postal_code = this.applicationForm.value.stepApplicant.address_postal_code;

      this.application.offer.bank_name =this.applicationForm.value.stepApplicant.bank_name;
      this.application.offer.account_number = this.applicationForm.value.stepApplicant.account_number;
      this.application.offer.account_type = this.applicationForm.value.stepApplicant.account_type;

    // } else if (currentStep === 2) {
      this.application.offer.gross_monthly_income = this.applicationForm.value.stepAffordability.gross_monthly_income;
      this.application.offer.net_monthly_income = this.applicationForm.value.stepAffordability.net_monthly_income;
      this.application.offer.total_monthly_credit_expenses = this.applicationForm.value.stepAffordability.total_monthly_credit_expenses;
      this.application.offer.total_monthly_living_expenses = this.applicationForm.value.stepAffordability.total_monthly_living_expenses;
    // } else if (currentStep === 3) {
      // handle step 4 to step 5 transitions
    // }
  }

  setInitStep() {
    this.wizardService.confirm_correct_page('wizard', this.application);

    this.updateApplicationStep(this.application.sub_status.identifier);
  }

  validateStep(stepIndex: number): boolean {
    const step = this.stepper.steps.toArray()[stepIndex];
    if (!step) return false;
    return step.stepControl.valid;
  }

  updateApplicationStep(location: SubStatusIdentifier) {
    const currentIndex = this.stepper.selectedIndex;
    const targetIndex = this.getStepIndex(location);

    if (targetIndex < currentIndex) {
      // Move to a previous step directly
      this.stepper.selectedIndex = targetIndex;
    } else {
      // Validate each step before moving forward
      for (let i = currentIndex; i < targetIndex; i++) {
        if (!this.validateStep(i)) {
          console.log(`Cannot move to step ${targetIndex}. Please complete step ${i} first.`);
          this.stepper.selectedIndex = i;
          return;
        }else{
          this.stepper.steps.toArray()[i].completed = true;
          console.log(`Validated step ${i}`);
        }
      }
      this.stepper.selectedIndex = targetIndex;
    }

    console.log('Updating application step to:', location);
    this.loading = false;
  }

  getStepIndex(location: SubStatusIdentifier): number {
    switch (location) {
      case SubStatusIdentifier.lead_otp_authenticated:
        return 0;
      case SubStatusIdentifier.in_progress_application_started:
        return 1;
      case SubStatusIdentifier.in_progress_applicant_details_confirmed:
        return 2;
      case SubStatusIdentifier.in_progress_affordability_declaration_completed:
        return 3;
      default:
        return 99;
    }
  }

  calculateWizardAndSteps() {
    this.setInitStep();
  }

  goBack() {
    console.log('Navigating Back');
    this.stepper.previous();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
  });
    this.calculateStepLocation();
    this.saveApplication();
  }

  goForward() {
    console.log('Navigating Forward');
    this.stepper.next();
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
  });
    this.calculateStepLocation();
    this.saveApplication();
  }

  submit(){
    console.log('Submit');
    this.submitApplication();
  }

  saveApplication() {
    this.updateModelFromFormValue();
    console.log('Saving application:', this.application);
    this.offerApi.saveApplication(this.application).subscribe({
      next: (res) => {
        if (res.success) {
          console.log('Application saved successfully:', res.data);
        } else {
          console.error('Error saving application:', res.message);
        }
      },
      error: (error) => {
        console.error('Error saving application:', error);
      },
      complete: () => {
      }
    });
  }

  submitApplication() {
    this.application.sub_status.identifier = SubStatusIdentifier.in_progress_summary_accepted;
    this.offerApi.saveApplication(this.application).subscribe({
      next: (res) => {
        if (res.success) {
          console.log('Application Submit successfully:', res.data);
          this.router.navigate(['/preagreement', res.data.identifier]);
        } else {
          console.error('Error Submit application:', res.message);
        }
      },
      error: (error) => {
        console.error('Error Submit application:', error);
      },
      complete: () => {
      }
    });
  }

  calculateStepLocation() {
    this.application.sub_status.identifier = this.getStepLocation(this.stepper.selectedIndex);
  }

  getStepLocation(stepIndex: number): SubStatusIdentifier {
    switch (stepIndex) {
      case 1:
        return SubStatusIdentifier.in_progress_application_started;
      case 2:
        return SubStatusIdentifier.in_progress_applicant_details_confirmed;
      case 3:
        return SubStatusIdentifier.in_progress_affordability_declaration_completed;
      case 4:
        return SubStatusIdentifier.in_progress_summary_accepted;
      default:
        return SubStatusIdentifier.in_progress_pending_verification;
    }
  }
}
