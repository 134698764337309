import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OfferApi } from '../../api/offerApi';
import { SessionService } from '../../services/session.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpVerificationComponent implements OnInit {
  @Input() identifier!: string;
  @Output() otpSuccess = new EventEmitter<any>();

  constructor(
    private offerApi: OfferApi,
    private sessionService: SessionService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    
    this.checkOtpRequirement();
  }

  checkOtpRequirement(): void {
    console.log('Checking OTP requirement...');
    if (!this.sessionService.checkOTPToken()) {
      this.offerApi.requestOtp(this.identifier).subscribe(response => {
        if (response.success) {
          // Handle successful OTP request
          this.openOtpDialog(response.data.contact_number);
        } else {
          this.openOtpDialog('0000001234');
          console.error('Error requesting OTP:', response.message);
        }
      });
    } else {
      this.otpSuccess.emit({ token: this.sessionService.getToken() });
    }
  }

  openOtpDialog(contact_number: string): void {
    const dialogRef = this.dialog.open(OtpDialogContentComponent, {
      maxWidth: '100%',
      data: { identifier: this.identifier, contact_number },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sessionService.storeToken(result.token);
        this.otpSuccess.emit(result);
      } else {
        console.log('OTP verification failed or cancelled');
        this.otpSuccess.emit(null);
      }
    });
  }
}

@Component({
  selector: 'otp-dialog-content',
  templateUrl: './otp-dialog-content.component.html',
  styleUrls: ['./otp-verification.component.scss']
})
export class OtpDialogContentComponent implements OnInit {
  otpForm!: FormGroup;
  timer: number = 30;
  interval: any;
  errorMessage: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { identifier: string; contact_number: string },
    private offerApi: OfferApi,
    private sessionService: SessionService,
    public dialogRef: MatDialogRef<OtpDialogContentComponent>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.otpForm = this.fb.group({
      otp: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]]
    });
    this.startTimer();
  }

  validateOtp(): void {

    console.log('OTP form:', this.otpForm.value);
    if (this.otpForm.invalid) {
      return; // Prevent validation if form is invalid
    }

    const otpValue = this.otpForm.get('otp')?.value;

    this.offerApi.validateOtp(this.data.identifier, otpValue).subscribe(response => {

      if (response.success && response.data.token) {
        this.dialogRef.close(response.data); // Close dialog with success result
      } else {
        this.errorMessage = response.message;
        this.otpForm.get('otp')?.setErrors({ invalidOtp: true });
      }
    });
  }

  startTimer(): void {
    this.timer = 30;
    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        clearInterval(this.interval);
      }
    }, 1000);
  }

  resetOtp(): void {
    if (this.timer <= 0) {
      this.offerApi.requestOtp(this.data.identifier).subscribe(response => {
        if (response.success) {
          this.data.contact_number = response.data.contact_number;
          this.startTimer();
        } else {
          console.error('Error requesting OTP:', response.message);
        }
      });
    }
  }
}
