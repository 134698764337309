export class Applicant {

  public id: number;
  public first_name: string;
  public last_name: string;
  public email: string;
  public contact_number: number;
  public id_number: number;
  public ethnicity: string;

  constructor(

  ) {}
}
