<nav class="landing-banner">
  <div class="top-banner-page d-flex justify-content-between align-items-center">
    <div class="d-flex flex-row align-items-center gap-16 font-size-20 font-weight-500 cursor-pointer" aria-hidden="true">
      <a routerLink="/" class="nav-logo logo-eduvos-black"></a>
      <div class="nav-split"></div>
      <a routerLink="/" class="nav-logo logo-sf-black"></a>
    </div>
    <div class="d-flex flex-row align-items-center gap-16 font-size-16 font-weight-500 cursor-pointer text-black" aria-hidden="true">
      <a routerLink="/status">Status checker</a>
      <a routerLink="/lead">Get estimate</a>
    </div>
  </div>
</nav>
