<div class="steps-tracker">
  @for (step of stepData; track step;let first = $first, last = $last;let i =$index) {
  <div class="step">
    <div class="step-circle" [ngClass]="{ 'completed': step.completed, 'rejected': step.name === 'Application rejected'}">
      <span class="step-icon"><mat-icon [fontIcon]="step.name === 'Application rejected' ? 'close' : 'check'"></mat-icon></span>
    </div>
    <p class="font-size-14 font-weight-bold mt-3">{{ step.name }}</p>
  </div>
  @if(!last) {
    <div class="step-line" [ngClass]="{ completed: step.completed && stepData[i + 1]?.completed }"></div>
    } 
  }
</div>