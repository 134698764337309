import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { OfferApi } from '../../api/offerApi';

@Component({
  selector: 'app-create-lead',
  templateUrl: './create-lead.component.html',
  styleUrls: ['./create-lead.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('600ms ease-in')]),
      transition(':leave', [animate('0ms ease-out')]),
    ]),
  ],
})
export class CreateLeadComponent implements OnInit {
  form: FormGroup;
  siteKey: string = environment.recaptcha.siteKey;
  currentStep: number = 0; // Initialize to step 0
  monthlyRepayment: number = 0;
  agents: { id: number, name: string }[] = [];
  errorMessage: string = '';
  bannerData: Array<{ title: string, description: string }> = [
    { title: 'Enter the student number', description: '', },
    { title: 'Check monthly repayment', description: '', },
    { title: 'Check if the sponsor qualifies', description: '', },
    { title: 'The sponsor potentially qualifies ', description: 'Provide the details of student and sponsor and we will send them a link to continue with the application', },
    { title: 'Application invite sent!', description: '', },
  ];

  constructor(
    private fb: FormBuilder,
    private offerApi: OfferApi,
  ) { }

  ngOnInit(): void {
    this.initializeForm();
    this.loadAgents();
    this.form.get('step1.course_fees')?.valueChanges.subscribe(() => this.calculateRepayment());
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      step0: this.fb.group({
        student_number: ['', Validators.required],
        recaptcha_reactive: [null, Validators.required], // Validators.required
      }),
      step1: this.fb.group({
        course_fees: [, Validators.required],
        start_date: ['', Validators.required],
      }),
      step2: this.fb.group({
        applicant_id_number: ['', Validators.required]
      }),
      step3: this.fb.group({
        applicant_first_name: ['', Validators.required],
        applicant_last_name: ['', Validators.required],
        applicant_email: ['', [Validators.required, Validators.email]],
        applicant_contact_number: ['', Validators.required],
        student_first_name: ['', Validators.required],
        student_last_name: ['', Validators.required],
        agent: ['', Validators.required],
      })
    });
  }

  private loadAgents(): void {
    this.agents = [
      { id: 1, name: 'Agent 1' },
      { id: 2, name: 'Agent 2' },
      { id: 3, name: 'Agent 3' }
    ];
  }

  calculateRepayment(): void {
    console.log('calculateRepayment');
    const courseFees = this.form.get('step1.course_fees')?.value;
    this.monthlyRepayment = courseFees ? courseFees / 24 : 0;

  }

  nextStep(): void {
    console.log('nextStep Step:', this.currentStep);
    this.errorMessage = '';
    if (this.getCurrentStepForm().valid) {
      if (this.currentStep === 0) {
        this.getLead();
      }
      else if (this.currentStep === 2) {
        this.createLead();
      } else if (
        this.currentStep === 3) {
        this.submitLead();
      } else {
        this.currentStep++;
      }

    } else {
      this.getCurrentStepForm().markAllAsTouched();
    }
  }

  previousStep(): void {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  getLead() {

    var studentNumber = this.form.get('step0.student_number')?.value;

    this.offerApi.getLeadFromStudentNumber(studentNumber).subscribe({
      next: (res) => {

        // console.log('Lead:', res);
        if (res.success) {
          this.form.get('step1.course_fees')?.setValue(res.data.loan_amount);
          this.form.get('step1.start_date')?.setValue(res.data.start_date);
          this.form.get('step2.applicant_id_number')?.setValue(res.data.applicant.id_number);
          this.form.get('step3.applicant_first_name')?.setValue(res.data.applicant.first_name);
          this.form.get('step3.applicant_last_name')?.setValue(res.data.applicant.last_name);
          this.form.get('step3.applicant_email')?.setValue(res.data.applicant.email);
          this.form.get('step3.applicant_contact_number')?.setValue(res.data.applicant.contact_number);
          this.form.get('step3.student_first_name')?.setValue(res.data.student.first_name);
          this.form.get('step3.student_last_name')?.setValue(res.data.student.last_name);
          this.form.get('step3.agent')?.setValue(res.data.agent);
          this.currentStep++;
        } else if (res.message === 'Student not found') {
          this.currentStep++;
        } else {
          this.errorMessage = res.message;
          console.error('Error fetching lead:', res.message);
        }
      },
      error: (error) => {
        this.errorMessage = 'An error occurred while fetching the lead. Please try again later.';
        console.error('Error fetching lead:', error);
      },
      complete: () => {
      }
    });
  }

  createLead() {

    console.log('createLead:', this.form.value);

    var lead = {

      loan_amount: this.form.get('step1.course_fees')?.value,
      start_date: this.form.get('step1.start_date')?.value,
      student_number: this.form.get('step0.student_number')?.value,
      recaptcha_reactive: this.form.get('step0.recaptcha_reactive')?.value,
      applicant_id_number: this.form.get('step2.applicant_id_number')?.value,
    };

    // console.log('Lead:', lead);

    this.offerApi.createLead(lead).subscribe({
      next: (res) => {
        if (res.success) {
          console.log('Application saved successfully:', res.data);
          this.currentStep++;
        } else {
          this.errorMessage = res.message;
          console.error('Error saving application:', res.message);
        }
      },
      error: (error) => {
        this.errorMessage = 'An error occurred while creating the lead. Please try again later.';
        console.error('Error saving application:', error);
      },
      complete: () => {
      }
    });
  }

  submitLead() {

    var lead = {
      loan_amount: this.form.get('step1.course_fees')?.value,
      start_date: this.form.get('step1.start_date')?.value,
      student_number: this.form.get('step0.student_number')?.value,
      applicant_id_number: this.form.get('step2.applicant_id_number')?.value,
      recaptcha_reactive: this.form.get('step0.recaptcha_reactive')?.value,
      applicant_first_name: this.form.get('step3.applicant_first_name')?.value,
      applicant_last_name: this.form.get('step3.applicant_last_name')?.value,
      applicant_email: this.form.get('step3.applicant_email')?.value,
      applicant_contact_number: this.form.get('step3.applicant_contact_number')?.value,
      student_first_name: this.form.get('step3.student_first_name')?.value,
      student_last_name: this.form.get('step3.student_last_name')?.value,
      agent: this.form.get('step3.agent')?.value,
    };

    this.offerApi.submitLead(lead).subscribe({
      next: (res) => {
        if (res.success) {
          console.log('Application submitted successfully:', res.data);
          this.currentStep++;
        } else {
          this.errorMessage = res.message;
          console.error('Error saving application:', res.message);
        }
      },
      error: (error) => {
        this.errorMessage = 'An error occurred while creating the lead. Please try again later.';
        console.error('Error saving application:', error);
      },
      complete: () => {
      }
    });
  }

  resolved(captchaResponse: string): void {
    this.form.get('step0.recaptcha_reactive')?.setValue(captchaResponse);
  }

  submitForm(): void {
    if (this.form.valid) {
      const formData = {
        ...this.form.get('step1')?.value,
        ...this.form.get('step2')?.value,
        ...this.form.get('step3')?.value
      };
      console.log('Form Data:', formData);
    }
  }

  private getCurrentStepForm(): FormGroup {
    return this.form.get(`step${this.currentStep}`) as FormGroup;
  }
}
