import { Injectable } from '@angular/core';
import { Application, SubStatusIdentifier, StatusIdentifier } from '../models/application';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { app } from '../../../server';

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  // Environment configuration is used to fetch the environment settings
  environment = environment;

  constructor(
    private router: Router
  ) { }

  confirm_correct_page(location: string, application: Application): boolean {

    // Define valid statuses for each location
    const validStatusesForLocation = {
      'wizard': [
        SubStatusIdentifier.lead_otp_authenticated,
        SubStatusIdentifier.in_progress_application_started,
        SubStatusIdentifier.in_progress_applicant_details_confirmed,
        SubStatusIdentifier.in_progress_affordability_declaration_completed
      ],
      'preagreement': [
        SubStatusIdentifier.in_progress_summary_accepted
      ],
      'documents': [
        SubStatusIdentifier.in_progress_quote_accepted_waiting_for_documents
      ]
    };

    // Application is not closed
    if (
      application.sub_status.identifier === SubStatusIdentifier.in_progress_pending_verification || 
      !Object.values(validStatusesForLocation).some(statusArray => statusArray.includes(application.sub_status.identifier))
    ) {
      this.navigateTo('/completed', application.identifier);
      return false;
    }

    const validStatuses = validStatusesForLocation[location] || [];

    // Check if current sub-status is valid for the given location
    if (validStatuses.includes(application.sub_status.identifier)) {
      return true;
    }

    // Wizzard sub statues
    const validWizardStatuses = validStatusesForLocation['wizard'];

    // Handle navigation based on location and sub-status
    switch (location) {
      case 'wizard':
        if (application.sub_status.identifier === SubStatusIdentifier.in_progress_summary_accepted) {
          this.navigateTo('/preagreement', application.identifier);
        } else if (application.sub_status.identifier === SubStatusIdentifier.in_progress_quote_accepted_waiting_for_documents) {
          this.navigateTo('/documents', application.identifier);
        }
        break;

      case 'preagreement':


        if (application.sub_status.identifier === SubStatusIdentifier.in_progress_quote_accepted_waiting_for_documents) {
          this.navigateTo('/documents', application.identifier);
        }

        if (validWizardStatuses.includes(application.sub_status.identifier)) {
          this.navigateTo('/continue', application.identifier);
        }

        break;

      case 'documents':

        if (application.sub_status.identifier === SubStatusIdentifier.in_progress_summary_accepted) {
          this.navigateTo('/preagreement', application.identifier);
        }
        
        if (validWizardStatuses.includes(application.sub_status.identifier)) {
          this.navigateTo('/continue', application.identifier);
        }
        break;
    }

    return false;
  }

  private navigateTo(route: string, identifier: string): void {
    this.router.navigate([route, identifier]);
  }


  verifyid_number(id_number: string): boolean {
    if (!id_number.match(/^\d{13}$/)) {
      return false;
    }

    const numArray = id_number.split('').map(Number);

    let errors = false;

    // Validate the day and month
    const idMonth = parseInt(`${numArray[2]}${numArray[3]}`, 10);
    const idDay = parseInt(`${numArray[4]}${numArray[5]}`, 10);

    if (idMonth < 1 || idMonth > 12 || idDay < 1 || idDay > 31) {
      errors = true;
    }

    // Validate citizenship
    // Allows SA citezens and permanent residents
    const idCitizenship = numArray[10];
    if (idCitizenship !== 0 && idCitizenship !== 1) {
      errors = true;
    }

    // Check Digit Verification
    const evenDigits: number[] = [];
    const oddDigits: number[] = [];
    // Loop through modified numArray, storing the keys and their values in the above arrays
    numArray.forEach((digit, index) => {
      if (index === 0 || index % 2 === 0) {
        oddDigits.push(digit);
      } else {
        evenDigits.push(digit);
      }
    });

    // use array pop method to remove the last digit from odd_digits and store it in checkDigit
    const checkDigit = oddDigits.pop();

    // All digits in odd positions (excluding the check digit) must be added together.
    const addedOdds = oddDigits.reduce((sum, digit) => sum + digit, 0);

    // All digits in even positions must be concatenated to form a 6 digit number.
    const concatenatedEvens = parseInt(evenDigits.join(''), 10);

    // This 6 digit number must then be multiplied by 2.
    const evensx2 = concatenatedEvens * 2;

    // Add all the numbers produced from the even numbers x 2
    const addedEvens = `${evensx2}`.split('').reduce((sum, digit) => sum + parseInt(digit, 10), 0);

    const sum = addedOdds + addedEvens;

    // get the last digit of the sum
    const lastDigit = parseInt(`${sum}`.slice(-1), 10);

    // calculate verify check digit
    const verifyCheckDigit = (10 - lastDigit) % 10;

    return verifyCheckDigit === checkDigit && !errors;
  }
}
