import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  
  constructor(private http: HttpClient) {}

  checkOTPToken(): boolean {
    return !!sessionStorage.getItem('otpToken');
  }

  storeToken(token: string): void {
    sessionStorage.setItem('otpToken', token);
  }

  clearToken(): void {
    sessionStorage.removeItem('otpToken');
  }

  getToken(): string | null {
    return sessionStorage.getItem('otpToken');
  }
}
