import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OfferApi } from '../../api/offerApi';
import { Application, SubStatusIdentifier } from '../../models/application';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'app-sign-preagreement',
  templateUrl: './sign-preagreement.component.html',
  styleUrl: './sign-preagreement.component.scss'
})
export class SignPreagreementComponent {

  @ViewChild(OtpVerificationComponent) otpVerificationComponent!: OtpVerificationComponent;

  isLoading: boolean = true; // Loader state\
  otpVerified: boolean = false;
  identifier: string;

  bannerData: Array<{ title: string, description: string }> = [
    { title: 'LOAN AGREEMENT',description: 'PRE-AGREEMENT STATEMENT AND QUOTATION',},
  ];

  currentStep = 0;

  creditProvider = {
    name: 'Tyme Bank Limited',
    ncrcpNumber: '10774',
    registration: '2015/231510/06',
    address: '123 Main Street, Johannesburg',
    contact: '0860 999 119',
  };

  quotationSummary = {
    initiationFee: 'R 1,050.00',
    numberOfInstalments: '24',
    monthlyServiceFee: 'R 69.00',
    frequency: 'Monthly',
    annualInterestRate: '12%',
    monthlyInterestRate: '1%',
    randValueOfInterest: 'R 7,600.00',
    costOfCreditLifeInsurance: 'R 300.00',
    creditCostMultiple: '1.15',
  };

  repaymentArrangement = {
    monthlyInstalmentDueDate: '25th of each month',
    firstInstalmentDueDate: '25th January 2025',
    methodOfPayment: 'Debit Order',
    debitOrderDeductionDate: '25th',
    initiationFeeNote: '*Initiation Fee of R 1,050.00 is included in the first instalment.',
    quotationValidity: 'This quotation is valid for 7 days.',
  };

  application: Application = new Application();

  constructor(
    public wizardService: WizardService,
    private router: Router,
    private route: ActivatedRoute,
    private offerApi: OfferApi,
  ) { }

  ngOnInit(): void {
    this.identifier = this.route.snapshot.paramMap.get('unique_id');
  }

  handleOtpSuccess(success: boolean): void {
    console.log('OTP verification success:', success);
    if (success) {
      this.getLeadFromIdentifier(this.identifier);
    } else {
      console.error('OTP verification failed');
    }
  }

  private getLeadFromIdentifier(unique_id: string): void {
    this.offerApi.getLeadFromIdentifier(unique_id).subscribe({
      next: (res) => {
        if (res.success) {
          this.application = res.data;
          this.wizardService.confirm_correct_page('preagreement', this.application);
          this.isLoading = false; // Stop loader
        } else {
        }
      },
      error: (error) => {
        console.error('Failed to fetch application data:', error);
        if (error.status === 401) {
          this.otpVerificationComponent.checkOtpRequirement();
        }
      },
      complete: () => {
      }
    });
  }

  submit() {
    this.application.sub_status.identifier = SubStatusIdentifier.in_progress_quote_accepted_waiting_for_documents;
    this.offerApi.signApplicationPreAgreement(this.application).subscribe({
      next: (res) => {
        if (res.success) {
          console.log('Application Submit successfully:', res.data);
          this.router.navigate(['/documents', this.application.identifier]);
        } else {
          console.error('Error Submit application:', res.message);
        }
      },
      error: (error) => {
        console.error('Error Submit application:', error);
      },
      complete: () => {
      }
    });
  }

}
