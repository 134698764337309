import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from './session.service';

export interface ApiResponse {
  success: boolean;
  message: string;
  info?: string;
  data?: any;
  detail?: string;
  total_pages?: number;
  exception?: ApiResponseException;
}

export interface ApiResponseException {
  message: string;
  file: string;
  line: number;
  code: number;
  trace: string;
}

export class ApiResponseModel { // Renamed Response to ApiResponseModel
  success: boolean;
  message: string;
  total_pages?: number;
  data: any;

  constructor(success: boolean, message: string, data: any) {
    this.success = success;
    this.message = message;
    this.data = data;
  }
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private router: Router, private sessionService: SessionService) {}

  getAuthHeaders(): HttpHeaders {
    const token = this.sessionService.getToken();
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  formatApiResponse() {
    return (source: Observable<ApiResponse>) => source.pipe(
      map(response => this.formattedResponse(response)),
      catchError(error => this.handleError(error))
    );
  }

  formattedResponse(response: ApiResponse): ApiResponseModel {
    const finalResponse = new ApiResponseModel(false, "Please contact system support.", {});
 
    if (response.success) {
      finalResponse.success = true;
      finalResponse.message = response.message;
      finalResponse.data = response.data;
      finalResponse.total_pages = response.total_pages;
    } else {
      finalResponse.message = this.getUserMessage(response.message);
      finalResponse.success = false;
    }

    return finalResponse;
  }

  handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'Something bad happened; please try again later.';

    if (error.status === 504) {
      errorMessage = "No backend server found.";
    } else if (error.status === 401) {
      // this.router.navigate(['/login']);
      this.sessionService.clearToken();
      errorMessage = "Not Authenticated";
    }

    // Swal.fire("Error", errorMessage);
    return throwError(error);
  }

  getUserMessage(errorMessage: string): string {
    if (!errorMessage) {
      return "There was an error on the server, please inform tech support.";
    }
    return errorMessage;
  }
}
