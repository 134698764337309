// banner.component.ts
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate('600ms ease-in')]),
      transition(':leave', [animate('0ms ease-out')]),
    ]),
  ],
})
export class BannerComponent implements OnInit, AfterViewInit {
  @Input() currentStep: number;
  @Input() data: { title: string, description: string }[] = [];

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {}

  ngAfterViewInit() {}
}
