// status-check.component.ts
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { OfferApi } from '../../api/offerApi';
@Component({
  selector: 'app-status-check',
  templateUrl: './status-check.component.html',
  styleUrls: ['./status-check.component.scss'],
})
export class StatusCheckComponent {

  bannerData: Array<{ title: string, description: string }> = [
    { title: 'Enter student number', description: 'to check the status of the application', },
  ];

  currentStep = 0;

  statusCheckForm: FormGroup;
  applicationStatus: string = '';

  public errorMessage = '';

  public stepData = [];

  private initialStepData = [
    { name: 'Lead created', completed: false },
    { name: 'Invite sent', completed: false },
    { name: 'Application started', completed: false },
    { name: 'Pending verification', completed: false },
    { name: 'Approved', completed: false }];

  public timeLineData = [];

  constructor(private fb: FormBuilder, private offerApi: OfferApi,) {
    this.statusCheckForm = this.fb.group({
      student_number: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.statusCheckForm.valid) {
      const student_number = this.statusCheckForm.get('student_number')?.value;
      this.checkApplicationStatus(student_number);
    }
  }

  checkApplicationStatus(student_number: string) {
    this.errorMessage = '';
    this.offerApi.getLeadFromStudentNumber(student_number).subscribe({
      next: (response) => {
        // console.log(response);

        if (response.success) {
          //Removing duplicate steps by not showing sub-status with same tracker step
          var filteredData = response.data.status_history.reduce((unique, o) => {
            if (!unique.some(obj => obj.sub_status.tracker_step === o.sub_status.tracker_step)) {
              unique.push(o);
            }
            return unique;
          }, []);


          //Reversing and mapping data to show completed steps first
          const completedStepData = filteredData.reverse().map((status: any) => {
            return {
              name: status.sub_status.tracker_step,
              completed: true
            };
          });

          //Add Not completed steps here
          const names = new Set(completedStepData.map(d => d.name));
          this.stepData = [...completedStepData, ...this.initialStepData.filter(d => !names.has(d.name))];

          this.timeLineData = filteredData.reverse().map((status: any) => {
            return {
              heading: status.sub_status.tracker_step,
              date: status.created_at,
              description: status.sub_status.tracker_description,
              action: status.sub_status.tracker_action
            };
          });


          this.applicationStatus = response.data.status;
          this.currentStep = 1;

          const element = document.getElementById("stepTracker");
          element?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });

        } else if (response.message === 'Student not found') {
          this.errorMessage = 'Student not found';
          this.stepData = this.initialStepData;
          this.timeLineData = [];
        } else {
          this.errorMessage = response.message;
          this.stepData = this.initialStepData;
          this.timeLineData = [];
        }
      },
      error: (error) => {
        console.log(error);
        this.stepData = this.initialStepData;
        this.timeLineData = [];
      }
    });
  }
}
