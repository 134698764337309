import { Applicant } from "./applicant";
import { Offer } from "./offer";
import { Student } from "./student";

export enum StatusIdentifier {
  lead = 'lead',
  in_progress = 'in_progress',
  rejected = 'rejected',
  pre_approved = 'pre_approved',
  approved = 'approved',
}

export enum SubStatusIdentifier{
  // lead
  lead_credit_check_passed_waiting_for_data='lead_credit_check_passed_waiting_for_data',
  lead_waiting_for_data='lead_waiting_for_data',
  lead_student_invite_sent='lead_student_invite_sent',
  lead_credit_check_passed='lead_credit_check_passed',
  lead_applicant_invite_sent='lead_applicant_invite_sent',
  lead_otp_authenticated='lead_otp_authenticated',

  // in_progress wizard steps
  in_progress_application_started='in_progress_application_started',
  in_progress_applicant_details_confirmed='in_progress_applicant_details_confirmed',
  in_progress_affordability_declaration_completed='in_progress_affordability_declaration_completed',
  in_progress_summary_accepted='in_progress_summary_accepted',

  // In progress pre-agreement
  in_progress_quote_accepted_waiting_for_documents='in_progress_quote_accepted_waiting_for_documents',

  // In progress document upload
  in_progress_pending_verification='in_progress_pending_verification',

  // rejected
  rejected_credit_check_failed='rejected_credit_check_failed',
  rejected_affordability_failed='rejected_affordability_failed',
  rejected_id_verification_failed='rejected_id_verification_failed',
  rejected_quote_expired='rejected_quote_expired',
  rejected_did_not_continue_application='rejected_did_not_continue_application',
  rejected_first_debit_order_failed='rejected_first_debit_order_failed',

  // pre_approved
  pre_approved='pre_approved',

  // approved
  approved='approved',
}

export class Status {
  id: number;
  identifier: StatusIdentifier;
  name: string;
  description?: string;
  order?: number;
}

export class SubStatus {
  id: number;
  identifier: SubStatusIdentifier;
  name: string;
  description?: string;
  order?: number;
}

export class Application {
    // 
    public identifier: string;
    public loan_amount: number;
    public monthly_amount: number;

    // 
    public applicant: Applicant;
    public student: Student;
    
    // 
    public offer: Offer;

    //
    public status: Status;
    public sub_status: SubStatus;

  constructor() {

    this.applicant = new Applicant();
    this.student = new Student();
    this.offer = new Offer();
  }

  onSubmit(){
    console.log('Application submitted');
  }
}
