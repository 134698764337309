<!-- Banner Component -->
<app-banner [currentStep]="currentStep" [data]="bannerData"></app-banner>

<div class="container pl-container-page">
    <!-- Loop through the required file types -->
    <div *ngFor="let fileType of fileTypes">
        <div class="pt-5 pb-3">
            <h2>{{ fileType.title }}</h2>
            <p>{{ fileType.description }}</p>

            <div class="row">
                <div class="col-12" *ngIf="fileType.warning != ''">
                    <div class="alert alert-danger" role="alert">
                        {{ fileType.warning }}
                    </div>  
                </div>
                <div class="col-12">
                    <div class="drop-zone-container" appDnd (fileDropped)="onFileDropped($event, fileType.name)">
                        <div class="files-list" *ngIf="files[fileType.name] && files[fileType.name].length > 0">
                            <div class="single-file" *ngFor="let file of files[fileType.name]; let i = index">
                                <div class="file-info">
                                    <div class="file-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/></svg>
                                    </div>
                                    <div class="info">
                                        <h4 class="name">{{ file?.name }}</h4>
                                        <p class="size">{{ formatBytes(file?.size) }}</p>
                                        <app-progress [progress]="file?.progress"></app-progress>
                                    </div>
                                    <div class="delete" (click)="deleteFile(fileType.name, i)">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="drop-zone" *ngIf=" files[fileType.name].length < maxFiles[fileType.name]">
                            <p>Drag and drop files here or</p>
                            <button class="btn btn-primary" (click)="fileInput.click()">Browse Files</button>
                            <input #fileInput type="file" multiple (change)="fileBrowseHandler(fileInput, fileType.name)" hidden />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Submit Button -->
    <div class="col-12">
        <button (click)="submit()" type="button" class="btn btn-common btn-yellow btn-secondary">Continue</button>
    </div>

</div>

<!-- Debugging: Display Application JSON -->
<!-- <pre style="margin-top: 50px; border: 1px solid red; width: fit-content;">
    {{ application | json }}
</pre> -->