import { Component } from '@angular/core';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrl: './completed.component.scss'
})
export class CompletedComponent {

  bannerData: Array<{ title: string, description: string }> = [
    { title: 'Application Completed',description: 'Thank you',},
  ];

  currentStep = 0;

}
