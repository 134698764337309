<div class="container pl-container-page">
  <form autocomplete="off" [formGroup]="form">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="font-size-14 font-size-md-16 font-weight-bold mb-2">Enter your income information</div>
      </div>
      <!-- Gross Monthly Income -->
      <div class="col-12 col-md-12">
        <div class="form-group">
          <label class="font-size-16" for="gross_monthly_income">Gross monthly income</label>
          <mat-form-field appearance="outline" class="w-100">
            <input currencyMask matInput id="gross_monthly_income" formControlName="gross_monthly_income">
            <mat-error *ngIf="form.get('gross_monthly_income').hasError('required')">Gross Monthly Income is
              required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- Net Monthly Income -->
      <div class="col-12 col-md-12">
        <div class="form-group">
          <label class="font-size-16" for="net_monthly_income">Net monthly income</label>
          <mat-form-field appearance="outline" class="w-100">
            <input currencyMask matInput id="net_monthly_income" formControlName="net_monthly_income">
            <mat-error>Net Monthly Income is required</mat-error>
            <mat-hint>Enter the exact amount as it's written on your bank statement.</mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="font-size-14 font-size-md-16 mt-3 mb-2">
          <div class="a cursor-pointer" [popoverTrigger]="popoverContent" [closeOnClickOutside]="true">
            What's the difference between Gross and Net income?
          </div>
        </div>
      </div>

      <div class="col-12 col-md-12 mt-3">
        <div class="font-size-14 font-size-md-16 font-weight-bold mt-3 mb-2">Enter your expense information</div>
      </div>

      <!-- Monthly Expenses -->
      <div class="col-12 col-md-12">
        <div class="form-group">
          <label class="font-size-16" for="total_monthly_credit_expenses">Total monthly credit repayments</label>
          <mat-form-field appearance="outline" class="w-100">
            <input currencyMask matInput id="total_monthly_credit_expenses"
              formControlName="total_monthly_credit_expenses">
          </mat-form-field>
        </div>
      </div>

      <!-- Monthly Expenses -->
      <div class="col-12 col-md-12">
        <div class="form-group">
          <label class="font-size-16" for="total_monthly_living_expenses">Total monthly living expenses</label>
          <mat-form-field appearance="outline" class="w-100">
            <input currencyMask matInput id="total_monthly_living_expenses"
              formControlName="total_monthly_living_expenses">
          </mat-form-field>
        </div>
      </div>

      <div class="col-12 col-md-12">
        <div class="font-size-14 font-size-md-16">
          <div class="a cursor-pointer" [popoverTrigger]="popoverContent2" [closeOnClickOutside]="true">
            What amounts should I use here?
          </div>
        </div>
      </div>
    </div>
  </form>
</div>



<ng-template #popoverContent>
  <div class="tooltip show landing-tooltip bs-tooltip-top" x-placement="top" data-placement="top">
    <div class="tooltip-inner" role="tooltip" aria-hidden="true">
      <div class="font-size-16 font-weight-bold">Gross income</div>
      <div class="font-size-14 item-label ">is your total income before tax and deductions.</div>
      <div class="font-size-16 font-weight-bold pt-3">Net income</div>
      <div class="font-size-14 item-label ">Is your income after tax, benefits and other deductions.</div>
      <div class="font-size-16 font-weight-bold pt-3">Your gross income is always bigger than your net income.</div>
    </div>
  </div>
</ng-template>

<ng-template #popoverContent2>
  <div class="tooltip show landing-tooltip bs-tooltip-top" x-placement="top" data-placement="top">
    <div class="tooltip-inner" role="tooltip" aria-hidden="true">
      <div class="font-size-16 font-weight-bold">Total monthly credit repayments:</div>
      <div class="font-size-14 item-label">Add up your account payments like store cards, credit cards and other loans
      </div>
      <div class="font-size-16 font-weight-bold pt-3">Total monthly living expenses:</div>
      <div class="font-size-14 item-label">Add up expenses like groceries, rent, school fees, transport, entertainment,
        etc.</div>
    </div>
  </div>
</ng-template>