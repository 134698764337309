<app-banner [currentStep]="currentStep" [data]="bannerData"></app-banner>

<div class="container pl-container-page">
  <form
    autocomplete="off"
    [formGroup]="statusCheckForm"
    (ngSubmit)="onSubmit()"
  >
    <!-- OTP -->
    <div class="row">

      <div class="col-12 col-md-12" *ngIf="errorMessage != ''">
        <div class="alert alert-danger" role="alert">
          {{ errorMessage }}
        </div>
      </div>
      <div class="col-12 col-md-12">
        <h2>Application status</h2>
      </div>

      <div class="col-12 col-md-12">
        <div class="form-group">
          <label for="student_number">Student number</label>
          <mat-form-field appearance="outline" class="w-100">
            <input
              matInput
              type="text"
              id="student_number"
              formControlName="student_number"
              class="form-control"
              required
            />
            <mat-error
              *ngIf="statusCheckForm.get('student_number').hasError('required')"
              >Student number is required</mat-error
            >
          </mat-form-field>
        </div>
      </div>

      <!-- Buttons -->
      <div class="col-12 col-md-12 d-flex mb-5">
        <div>
          <button
            type="submit"
            class="btn btn-common btn-yellow btn btn-secondary"
          >
            Check Status
          </button>
        </div>
      </div>

      <div id="stepTracker" class="col-12 col-md-12">
        <div *ngIf="applicationStatus">
          <h3>Application Found:</h3>
          <!-- Steps Tracker Component -->
          <app-step-tracker [stepData]="stepData"></app-step-tracker>
          <!-- Timeline Component -->
          <app-timeline [timelineData]="timeLineData"></app-timeline>
        </div>
      </div>
    </div>
  </form>
</div>
