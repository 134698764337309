<app-banner [currentStep]="currentStep" [data]="bannerData"></app-banner>

<div class="container pl-container-page">
  <form autocomplete="off" [formGroup]="form">
    <!-- Back Button -->
    <div class="pb-3">
      <div *ngIf="currentStep >= 1 && currentStep <= 2">
        <div class="btn-icon-back" aria-hidden="true" (click)="previousStep()">
          <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M24.3562 11H9.49882L16.3186 5.4L14.6137 4L4.87109 12L14.6137 20L16.3186 18.6L9.49882 13H24.3562V11Z"
              fill="#231F20"></path>
          </svg>
        </div>
      </div>
    </div>

    <!-- Step 1: Student Number -->
    <div *ngIf="currentStep === 0">
      <div [formGroupName]="'step0'">
        <div class="row">
          <div class="col-12 col-md-12" *ngIf="errorMessage != ''">
            <div class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="student_number">Enter student number (or student ID)</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput id="student_number" formControlName="student_number" />
                <mat-error *ngIf="form.get('step0.student_number').hasError('required')">Student number is
                  required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- reCAPTCHA v2 -->
        <div class="row">
          <div class="col-12 col-md-12">
            <re-captcha (resolved)="resolved($event)"> </re-captcha>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 2 Course Details -->
    <div *ngIf="currentStep === 1">
      <div [formGroupName]="'step1'">
        <div class="row">
          <div class="col-12 col-md-12" *ngIf="errorMessage != ''">
            <div class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="course_fees">Course fees</label>
              <mat-form-field appearance="outline" class="w-100">
                <input currencyMask matInput id="course_fees" formControlName="course_fees" />
                <mat-error *ngIf="form.get('step1.course_fees').hasError('required')">Course fees is
                  required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="start_date">Intake</label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select formControlName="start_date" placeholder="Select">
                  <mat-option *ngFor="let option of ['Block 1', 'Block 2', 'Block 3']" [value]="option">
                    {{ option }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('step1.start_date').hasError('required')">Start date is required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="monthlyRepayment !== 0">
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label>Repayment over 24 months: </label>
              <div class="font-size-50 font-weight-bold mt-4 mb-4">
                <label>{{
                  monthlyRepayment | currency : "R" : "symbol"
                  }}</label>
              </div>

              <label>*This calculation is only intended to be indicative, final
                amount will be dependent on when the application is finalised
                and will be confirmed during the application process</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 3: Responsible Person ID -->
    <div *ngIf="currentStep === 2">
      <div [formGroupName]="'step2'">
        <div class="row">
          <div class="col-12 col-md-12" *ngIf="errorMessage != ''">
            <div class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="applicant_id_number">You need to provide the ID number of the person who
                will be
                responsible for the payment here</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput id="applicant_id_number" formControlName="applicant_id_number" />
                <mat-error *ngIf="
                    form.get('step2.applicant_id_number').hasError('required')
                  ">ID number is required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 4 applicant and Student Details -->
    <div *ngIf="currentStep === 3">
      <div [formGroupName]="'step3'">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="alert alert-success" role="alert">
              The sponsor has passed the credit check, please provide the
              details below so that we can kick-off the application process.
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="applicant_first_name">Sponsor first name</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput id="applicant_first_name" formControlName="applicant_first_name" />
                <mat-error *ngIf="form.get('step3.applicant_first_name').hasError('required') ">Sponsor first name is
                  required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="applicant_last_name">Sponsor surname</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput id="applicant_last_name" formControlName="applicant_last_name" />
                <mat-error *ngIf="
                    form.get('step3.applicant_last_name').hasError('required')
                  ">Sponsor last name is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="applicant_email">Sponsor email</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput id="applicant_email" formControlName="applicant_email" />
                <mat-error *ngIf="form.get('step3.applicant_email').hasError('required')">Sponsor email is
                  required</mat-error>
                <mat-error *ngIf="form.get('step3.applicant_email').hasError('email')">Enter a valid email</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="applicant_contact_number">Sponsor contact number</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput id="applicant_contact_number" formControlName="applicant_contact_number" />
                <mat-error *ngIf="
                    form
                      .get('step3.applicant_contact_number')
                      .hasError('required')
                  ">Sponsor contact number is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="student_first_name">Student name</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput id="student_first_name" formControlName="student_first_name" />
                <mat-error *ngIf="
                    form.get('step3.student_first_name').hasError('required')
                  ">Student name is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="student_last_name">Student surname</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput id="student_last_name" formControlName="student_last_name" />
                <mat-error *ngIf="form.get('step3.student_last_name').hasError('required')">Student surname is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-12 col-md-12">
            <div class="form-group">
              <label class="font-size-16" for="agent">Agent</label>
              <mat-form-field appearance="outline" class="w-100">
                <mat-select id="agent" formControlName="agent" placeholder="Select">
                  <mat-option *ngFor="let agent of agents" [value]="agent.id">
                    {{ agent.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('step3.agent').hasError('required')">Agent selection is required</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 5: Completed -->
    <div *ngIf="currentStep === 4">
      <div>
        <!--  [formGroupName]="'step3'" -->
        <div class="container mb-5">
          <div class="row">
            <!-- Month 1 -->
            <div class="col-12 col-md-12 d-flex">
              <div class="card text-center">
                <div style="
                    border-radius: 200px;
                    height: 200px;
                    width: 200px;
                    background: #f8faf5;
                    margin: 0 auto;">
                  <i class="checkmark">✓</i>
                </div>
                <h1>Thank you</h1>
                <p>
                  Thanks for submitting the application request, we have sent an
                  email to {{ form.get("step3.applicant_email").value }} to
                  continue with the application. <br />
                  We will keep you updated on the progress of the application.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="row mt-4 mb-5">
      <!-- Next Button -->
      <div class="col-12 d-flex" *ngIf="currentStep < 3">
        <div>
          <button (click)="nextStep()" type="button" class="btn btn-common btn-yellow btn btn-secondary">
            Next
          </button>
        </div>
      </div>

      <!-- Navigation buttons -->
      <div class="col-12 d-flex" *ngIf="currentStep === 3">
        <div>
          <button (click)="nextStep()" type="button" class="btn btn-common btn-yellow btn btn-secondary">
            Submit
          </button>
        </div>
      </div>
    </div>
  </form>
</div>