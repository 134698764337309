import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicantComponent } from './components/step/applicant/applicant.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { ApplicationComponent } from './components/application/application.component';
import { DocumentsUploadComponent } from './components/documents-upload/documents-upload.component';
import { CompletedComponent } from './components/completed/completed.component';
import { CreateLeadComponent } from './components/create-lead/create-lead.component';
import { SignPreagreementComponent } from './components/sign-preagreement/sign-preagreement.component';
import { StatusCheckComponent } from './components/status-check/status-check.component';


const routes: Routes = [
  { path: '', component: ApplicationComponent },
  { path: 'continue/:unique_id', component: WizardComponent },
  { path: 'preagreement/:unique_id', component: SignPreagreementComponent },
  { path: 'documents/:unique_id', component: DocumentsUploadComponent },
  { path: 'completed/:unique_id', component: CompletedComponent },
  { path: 'status', component: StatusCheckComponent },
  { path: 'lead', component: CreateLeadComponent },
  { path: '**', component: ApplicationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false,scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
