import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-progress',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  @Input() progress: number = 0;

  progressValue: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['progress']) {
      this.progressValue = this.progress;
    }
  }
}
