<app-banner [currentStep]="currentStep" [data]="bannerData"></app-banner>

<div class="container justify-content-center align-items-center mt-5 mb-5">
    <div class="row">
        <!-- Month 1 -->
        <div class="col-12 col-md-12 d-flex justify-content-center">
            <div class="card text-center">
                <div style="border-radius: 200px; height: 200px; width: 200px; background: #F8FAF5; margin: 0 auto;">
                    <i class="checkmark">✓</i>
                </div>
                <h1>Thank you</h1> 
                <p>We received all the information we need for now<br/> we'll be in touch shortly!</p>
            </div>
        </div>
    </div>
</div>
