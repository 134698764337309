import { Component, Input, OnInit } from '@angular/core';
import { Application } from '../../../models/application';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { WizardComponent } from '../../wizard/wizard.component';

@Component({
  selector: 'app-step-summary',
  templateUrl: './summary.component.html',
  styleUrl: './summary.component.scss'
})
export class SummaryComponent implements OnInit {
  @Input() application: Application;

  @Input() formGroupName: string;
  form: FormGroup;

  private monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  constructor(
    private rootFormGroup: FormGroupDirective,
    private wizardComponent: WizardComponent,
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }

  getMonthName(monthNumber: string | number): string {
    // Convert the string to a number
    const monthIndex = +monthNumber - 1; // Subtract 1 because array index starts from 0

    // Return the month name
    return this.monthNames[monthIndex];
  }
}
