<nav class="landing-banner">
  <div class="container d-flex align-items-center">
    <div class="" type="fadeIn" direction="normal">
      <div
        class="banner-bg"
        style="background-image: url('/assets/static/media/article-4-option-3-1785111.c672f634.png');"
      ></div>
    </div>
    <div class="banner-header-text d-flex flex-column justify-content-end col-md-12">
      <div *ngFor="let item of data; let i = index" @fadeInOut>
        <div *ngIf="i === currentStep">
          <!-- Small screen view -->
          <div class="d-sm-block d-md-none">
            <div class="" type="fadeInFromLeft" direction="normal">
              <h1 class="banner-header-title">{{ item.title }}</h1>
            </div>
            <div class="" type="fadeInFromLeft" direction="normal">
              <div class="banner-header-content">{{ item.description }}</div>
            </div>
          </div>
          <!-- Large screen view -->
          <div class="d-none d-sm-none d-md-block">
            <div class="" type="popIn" direction="normal">
              <h1 class="banner-header-title">{{ item.title }}</h1>
            </div>
            <div class="" type="fadeInFromBottom" direction="normal">
              <div class="banner-header-content">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
