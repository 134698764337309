import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  private popoverState = new Subject<boolean>();
  popoverState$ = this.popoverState.asObservable();

  openPopover() {
    this.popoverState.next(true);
  }

  closePopover() {
    this.popoverState.next(false);
  }

  getState() {
    return this.popoverState;
  }
}
