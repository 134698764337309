<div class="container pl-container-page">
  <form autocomplete="off" [formGroup]="form">
    <mat-card class="form-container mb-3">
      <table class="table table-responsive-md table-striped">
        <tbody>
          <!-- Full name -->
          <tr>
            <td><div class="item-label p-0">Full name</div></td>
            <td><div class="item-value text-right">{{ application.applicant.first_name }} {{application.applicant.last_name}}</div></td>
          </tr>
          <!-- email -->
          <tr>
            <td><div class="item-label p-0">Email</div></td>
            <td><div class="item-value text-right">{{ application.applicant.email }}</div></td>
          </tr>
          <!-- Contact number -->
          <tr>
            <td><div class="item-label p-0">Contact number</div></td>
            <td><div class="item-value text-right">{{ application.applicant.contact_number }}</div></td>
          </tr>
          <!-- Contact ID Number -->
          <tr>
            <td><div class="item-label p-0">ID number</div></td>
            <td><div class="item-value text-right">{{ application.applicant.id_number }}</div></td>
          </tr>
          <!-- Amount-->
          <tr>
            <td><div class="item-label p-0">Amount</div></td>
            <td><div class="item-value text-right">{{ application.loan_amount }}</div></td>
          </tr>
          <!-- Monthly Amount-->
          <tr>
            <td><div class="item-label p-0">Monthly amount</div></td>
            <td><div class="item-value text-right">{{ application.monthly_amount }}</div></td>
          </tr>
          <!-- Term-->
          <tr>
            <td><div class="item-label p-0">Term</div></td>
            <td><div class="item-value text-right">24 Months</div></td>
          </tr>
        </tbody>
      </table>
    </mat-card>

    <div class="col-12 col-md-12">
      <div class="font-size-14 font-size-md-16 mt-3">
        <div class="a" [popoverTrigger]="popoverContent" [closeOnClickOutside]="true">
          If any of the details above are incorrect, please contact us.
        </div>
      </div>
    </div>

    <div class="d-inline tip-content"> <br><br></div>

    <div class="d-inline tip-content" style="display: inline-block; gap: 8px; vertical-align: middle;">
      <div class="tip-icon" style="display: inline-block;">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22.3125C17.6954 22.3125 22.3125 17.6954 22.3125 12C22.3125 6.30456 17.6954 1.6875 12 1.6875C6.30456 1.6875 1.6875 6.30456 1.6875 12C1.6875 17.6954 6.30456 22.3125 12 22.3125Z" stroke="#31BB6D" stroke-width="1.125"></path>
          <path d="M11.5312 10.2188C11.5312 9.90809 11.7831 9.65625 12.0938 9.65625C12.4044 9.65625 12.6562 9.90809 12.6562 10.2188V16.6185C12.6562 16.9292 12.4044 17.181 12.0938 17.181C11.7831 17.181 11.5312 16.9292 11.5312 16.6185V10.2188Z" fill="#31BB6D"></path>
          <path d="M11.5312 7.59375C11.5312 7.28309 11.7831 7.03125 12.0938 7.03125C12.4044 7.03125 12.6562 7.28309 12.6562 7.59375V8.34375C12.6562 8.65441 12.4044 8.90625 12.0938 8.90625C11.7831 8.90625 11.5312 8.65441 11.5312 8.34375V7.59375Z" fill="#31BB6D"></path>
        </svg>
      </div>
      <div class="d-inline tip-content"> By continuing you agree to our <a href="https://www.eduvos.co.za/inchannel/privacy-policy.html" class="">Privacy Policy</a>.
      </div>
    </div>

  </form>
</div>


<ng-template #popoverContent>
  <div class="tooltip show landing-tooltip bs-tooltip-top" x-placement="top" data-placement="top">
    <div class="tooltip-inner" role="tooltip" aria-hidden="true">
      <div class="font-size-16 font-weight-bold">Contact us</div>
      <div class="font-size-14 item-label">
        Email: <a href="mailto:email@example.co.za">email&#64;example.co.za</a>
      </div>
      <div class="font-size-14 item-label">
        Contact number: <a href="tel:+003334444">00 333 4444</a>
      </div>
    </div>
  </div>
</ng-template>