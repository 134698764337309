import { Component, Input, OnInit } from '@angular/core';
import { Application } from '../../../models/application';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { WizardComponent } from '../../wizard/wizard.component';
import { PopoverService } from '../../../services/popover.service';

@Component({
  selector: 'app-step-affordability',
  templateUrl: './affordability.component.html',
  styleUrl: './affordability.component.scss'
})
export class AffordabilityComponent implements OnInit {
  @Input() application: Application;

  @Input() formGroupName: string;
  form: FormGroup;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private wizardComponent: WizardComponent,
    private popoverService: PopoverService
  ) { }

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
  }
}
