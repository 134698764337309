<div class="timeline-container">
  @for (item of timelineData; track item;let first = $first, last = $last;let i = $index){
  <div class="timeline-item">
    <div class="timeline-side">
      <div class="timeline-icon"></div>
      @if(!last){
        <div class="timeline-line"></div>
      }
    </div>
    <div class="timeline-content mb-4">
      <h3 class="font-weight-bold">{{ item.heading }}</h3>
      <p>{{ item.date | date:'yyyy-MM-dd hh:mm' }}</p>
      <p>{{ item.description }}</p>
      @if (first) {
        <p class="font-weight-bold">Action: {{ item.action }}</p>
      }
    </div>
  </div>
  }
</div>