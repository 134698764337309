
/** This point to the exact location (step and location in step) in the wizrds where the user is currently
 ** Next steps might include somthing like 'STEP_AFFORDABILITY_CONTINUE_POPUP
 **/

export class Offer {
  public id: number;

  // Employer details 
  public employer_name: string;
  public employment_status: string;
  public salary_date: string;
  public employer_start_month: string;
  public employer_start_year: string;

  public address_street: string;
  public address_suburb: string;
  public address_city: string;
  public address_province: string;
  public address_postal_code: string;

  // Bank details
  public bank_name: string;
  public account_number: string;
  public account_type: string;

  //Affordability
  public gross_monthly_income: number;
  public net_monthly_income: number;
  public total_monthly_credit_expenses: number;
  public total_monthly_living_expenses: number;
  
  constructor() {
    
  }
}
