import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.scss'
})
export class TimelineComponent {

  
  @Input() timelineData: Array<{ heading: string; date: string; description?: string; action?: string}> = []

  constructor() {}

}
