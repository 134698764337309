import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { of, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiResponseModel, HttpService } from '../services/http-service';
import { SessionService } from '../services/session.service';
import { Application } from '../models/application';

@Injectable({
  providedIn: 'root',
})
export class OfferApi {

  constructor(private http: HttpClient, private httpService: HttpService) {}

  createLead(leadData: any): Observable<ApiResponseModel> {
    const url = `${environment.url}/eduvos/lead/create/`;

    return this.http.post<ApiResponseModel>(url, leadData).pipe(
      this.httpService.formatApiResponse()
    );
  }

  submitLead(leadData: any): Observable<ApiResponseModel> {
    const url = `${environment.url}/eduvos/lead/submit/`;

    return this.http.put<ApiResponseModel>(url, leadData).pipe(
      this.httpService.formatApiResponse()
    );
  }

  getLeadFromIdentifier(identifier: string): Observable<ApiResponseModel> {
    const url = `${environment.url}/eduvos/offer/${identifier}/get/`;
    const headers = this.httpService.getAuthHeaders();

    return this.http.get<ApiResponseModel>(url, { headers }).pipe(
      this.httpService.formatApiResponse()
    );
  }

  getLeadFromStudentNumber(student_number: string): Observable<ApiResponseModel> {
    const url = `${environment.url}/eduvos/lead/${student_number}/get/`;
    const headers = this.httpService.getAuthHeaders();

    return this.http.get<ApiResponseModel>(url, { headers }).pipe(
      this.httpService.formatApiResponse()
    );
  }

  saveApplication(applicationData: any): Observable<ApiResponseModel> {
    const url = `${environment.url}/eduvos/offer/${applicationData.identifier}/update/`;
    const headers = this.httpService.getAuthHeaders();

    return this.http.post<ApiResponseModel>(url, applicationData, { headers }).pipe(
      this.httpService.formatApiResponse()
    );
  }

  signApplicationPreAgreement(applicationData: any): Observable<ApiResponseModel> {
    const url = `${environment.url}/eduvos/offer/${applicationData.identifier}/sign_pre_agreement/`;
    const headers = this.httpService.getAuthHeaders();

    return this.http.post<ApiResponseModel>(url, applicationData, { headers }).pipe(
      this.httpService.formatApiResponse()
    );
  }

  requestOtp(identifier: string): Observable<ApiResponseModel> {
    const url = `${environment.url}/eduvos/otp/${identifier}/request/`;

    return this.http.get<ApiResponseModel>(url).pipe(
      this.httpService.formatApiResponse()
    );
  }

  validateOtp(identifier: string, otp: string): Observable<ApiResponseModel> {
    const url = `${environment.url}/eduvos/otp/${identifier}/verify/`;
  
    return this.http.post<ApiResponseModel>(url, { otp }).pipe(
      map(event => this.httpService.formattedResponse(event)),
      catchError(error => of(error.error))
    );
  }

  uploadSupportingDocuments(applicationData: Application, document_type: string, file: File | any): Observable<number> {
    const url = `${environment.url}/eduvos/offer/${applicationData.identifier}/document/${document_type}/upload/`;
    const headers = this.httpService.getAuthHeaders();

    const formData = new FormData();
    formData.append('file', file);

    const uploadReq = new HttpRequest('POST', url, formData, {
      headers,
      reportProgress: true, // Enable progress tracking
    });

    return this.http.request(uploadReq).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress && event.total) {
          const percentDone = Math.round((100 * event.loaded) / event.total);
          return percentDone;
        } else if (event instanceof HttpResponse) {
          return 100; // File upload completed
        }
        return 0; // Default progress
      }),
      catchError((error: any) => {
        return throwError(error); // Handle HTTP errors
      })
    );
  }


  // upload(file: File, fileType: string): Observable<HttpEvent<any>> {
  //   const url = `${environment.url}/eduvos/upload/`;

  //   const formData = new FormData();
  //   formData.append('file', file);
  //   formData.append('fileType', fileType);

  //   const headers = new HttpHeaders({
  //     'Accept': 'application/json',
  //   });

  //   const req = new HttpRequest('POST', url, formData, {
  //     headers,
  //     reportProgress: true,
  //     observe: 'events',
  //   });

  //   return this.http.request(req);
  // }
}
