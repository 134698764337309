import {
    Directive,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
    ElementRef,
    HostListener
  } from "@angular/core";
  import {
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayRef
  } from "@angular/cdk/overlay";
  import { takeUntil } from "rxjs/operators";
  import { Subject } from "rxjs";
  import { TemplatePortal } from "@angular/cdk/portal";
  import { PopoverService } from "../services/popover.service";
  
  @Directive({
    selector: "[popoverTrigger]"
  })
  export class PopoverDirective implements OnDestroy, OnInit {
    @Input()
    popoverTrigger!: TemplateRef<any>;
  
    @Input()
    closeOnClickOutside: boolean = false;
  
    private unsubscribe = new Subject<void>();
    private overlayRef!: OverlayRef;
  
    constructor(
      private elementRef: ElementRef,
      private overlay: Overlay,
      private vcr: ViewContainerRef,
      private popoverService: PopoverService
    ) {}
  
    ngOnInit(): void {
      this.createOverlay();
      this.popoverService.getState().subscribe(resp => {
        if (resp) {
          this.detachOverlay();
        }
      });
    }
  
    ngOnDestroy(): void {
      this.detachOverlay();
      this.unsubscribe.next();
      this.unsubscribe.complete();
    }
  
    @HostListener("click", ["$event"]) onClick(event: Event): void {
      this.toggleOverlay();
    }
  
    private createOverlay(): void {
      const scrollStrategy = this.overlay.scrollStrategies.block();
      const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay.position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          }
        ])
        .withFlexibleDimensions(false)
        .withPush(false);
  
      this.overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy,
        hasBackdrop: true,
        backdropClass: ""
      });
  
      this.overlayRef
        .backdropClick()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          if (this.closeOnClickOutside) {
            this.detachOverlay();
          }
        });
    }
  
    private toggleOverlay(): void {
      if (!this.overlayRef.hasAttached()) {
        this.attachOverlay();
      } else {
        this.detachOverlay();
      }
    }
  
    private attachOverlay(): void {
      const portal = new TemplatePortal(this.popoverTrigger, this.vcr);
      this.overlayRef.attach(portal);
    }
  
    private detachOverlay(): void {
      if (this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
    }
  }
  