<div class="main">

  <div id="root" class="pl-container-fluid">
  <!-- Header needed -->
  <app-header></app-header>
  <!-- body -->
  <router-outlet></router-outlet>
  <!-- Footer  -->
  <app-footer></app-footer>
  </div>
</div>