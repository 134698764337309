export class Student {

  public id: number;
  
  public first_name: string;
  public last_name: string;
  public email: string;

  public student_number: string;
  public contact_number: number;
  public id_number: number;

  constructor(
  ) {
    this.id = 0;
    this.first_name = '';
    this.last_name = '';
  }
}
