<div class="container pl-container-page">
  <form autocomplete="off" [formGroup]="form">

    <!-- Loan Amount -->
    <div class="section">
      <div class="font-size-16 font-weight-bold mb-3">Loan Amount</div>
      <div class="form-group">
        <p class="font-weight-bold">{{ application.loan_amount | currency:'R':'symbol' }}</p>
      </div>
    </div>

    <!-- Personal Details -->
    <div class="section">
      <div class="font-size-16 font-weight-bold mt-4 mb-3">Personal Details</div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>First name:</label>
            <p class="font-weight-bold">{{ application.applicant.first_name }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Surname:</label>
            <p class="font-weight-bold">{{ application.applicant.last_name }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Email:</label>
            <p class="font-weight-bold">{{ application.applicant.email }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Employment status:</label>
            <p class="font-weight-bold">{{ application.offer.employment_status }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Current company name:</label>
            <p class="font-weight-bold">{{ application.offer.employer_name }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Start date:</label>
            <p class="font-weight-bold">
              {{ getMonthName(application.offer.employer_start_month) }} 
              {{ application.offer.employer_start_year }}
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Salary date:</label>
            <p class="font-weight-bold">{{ application.offer.salary_date }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Race:</label>
            <p class="font-weight-bold">{{ application.applicant.ethnicity }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Physical <a href="http://"></a>ddress:</label>
            <p class="font-weight-bold">{{ application.offer.address_street }}, {{ application.offer.address_suburb }}, {{ application.offer.address_city }}, {{ application.offer.address_province }}, {{ application.offer.address_postal_code }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Bank account details:</label>
            <p class="font-weight-bold">Bank name: {{ application.offer.bank_name }}</p>
            <p class="font-weight-bold">Account number: {{ application.offer.account_number }}</p>
            <p class="font-weight-bold">Account type: {{ application.offer.account_type }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Affordability -->
    <div class="section">
      <div class="font-size-16 font-weight-bold mt-4 mb-3">Affordability</div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Monthly gross income:</label>
            <p class="font-weight-bold">{{ application.offer.gross_monthly_income | currency:'R':'symbol' }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Monthly net income:</label>
            <p class="font-weight-bold">{{ application.offer.net_monthly_income | currency:'R':'symbol' }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Debt commitments:</label>
            <p class="font-weight-bold">{{ application.offer.total_monthly_credit_expenses | currency:'R':'symbol' }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Living expenses:</label>
            <p class="font-weight-bold">{{ application.offer.total_monthly_living_expenses | currency:'R':'symbol' }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="form-check mt-3">
          <input formControlName="credit_check_consent" class="form-check-input" type="checkbox" id="credit_check_consent" required>
          <label class="form-check-label" for="credit_check_consent">
            I consent to the terms and conditions.
          </label>
      </div>
    </div>

    <!-- Consent & ReCAPTCHA -->
    <div class="col-md-12">
      <div class="form-check mt-3">
          <input formControlName="recaptcha" class="form-check-input" type="checkbox" id="recaptcha" required>
          <label class="form-check-label" for="recaptcha">
            I am not a robot.
          </label>
      </div>
    </div>
  </form>
</div>
